import { AxiosRequestConfig } from "axios";
import { Repository } from "./Repository";
import { EditorPanoLink, formatPanoLinks } from "../../../utils/editorUtils";
import { ICanCreate } from "./ICanCreate";
import { ICanRead } from "./ICanRead";
import { ICanUpdate } from "./ICanUpdate";
import { ICanDelete } from "./ICanDelete";

export class PanoLinkRepository extends Repository implements ICanCreate<EditorPanoLink>, ICanRead<EditorPanoLink>, ICanUpdate<EditorPanoLink>, ICanDelete<EditorPanoLink> {
/**
 * Fetches editor pano links based on a target number.
 * @param {AxiosRequestConfig | undefined} restAuth - Used to provide authentication and configuration options
 * for making requests.
 * @param {number} target - Specifies the panorama id for which the panorama links are being retrieved.
 * @returns If the request succeeded, the data from the response is formatted and returned. Otherwise, [] is returned.
 */
  async get(restAuth: AxiosRequestConfig | undefined, target: number): Promise<EditorPanoLink[]> {
    const data = await this.api.get(
      restAuth,
      `/sep_edit/v1/pano/${target}/pano_link?context=edit`,
    );
    if (data) {
      return formatPanoLinks(data);
    }
    return [];
  }

  async single(): Promise<EditorPanoLink> {
    return {} as EditorPanoLink;
  }

  async create(restAuth: AxiosRequestConfig | undefined, item: EditorPanoLink, target: number): Promise<EditorPanoLink[]> {
    const data = await this.api.create(
      restAuth,
      `/sep_edit/v1/pano/${target}/pano_link`,
      item,
    );

    if (data) {
      return formatPanoLinks(data);
    }
    return [];
  }

  async update(restAuth: AxiosRequestConfig | undefined, item: Object, index: number, target: number): Promise<EditorPanoLink[]> {
    const data = await this.api.update(
      restAuth,
      `/sep_edit/v1/pano/${target}/pano_link/${index}`,
      item,
    );
    if (data) {
      return formatPanoLinks(data);
    }
    return [];
  }

  async delete(restAuth: AxiosRequestConfig | undefined, index: number, target: number): Promise<EditorPanoLink[]> {
    const data = await this.api.delete(
      restAuth,
      `/sep_edit/v1/pano/${target}/pano_link/${index}`,
    );

    if (data) {
      return formatPanoLinks(data);
    }
    return [];
  }
}