import { PanoHotspotTypeMap } from "../types";

export const formatCoordinate = (input: string | number): number =>
  Number(Number(input).toFixed(2));

export const formatPanoLink = ({
  hotspot_type,
  hotspot_coordinates,
  panorama,
  yaw,
  pitch,
}: EditorPanoLinkRaw): EditorPanoLink => {
  yaw = formatCoordinate(yaw);
  pitch = formatCoordinate(pitch);
  const coordinates = hotspot_coordinates
    ? hotspot_coordinates.map(({ pitch: p, yaw: y }) => ({
        pitch: formatCoordinate(p),
        yaw: formatCoordinate(y),
      }))
    : [{ pitch, yaw }];

    switch(true) {
      case coordinates.length >= 2 && hotspot_type === "polyline":
        hotspot_type = "polyline";
        break;
      case coordinates.length >= 2 && hotspot_type === "polygon":
        hotspot_type = "polygon";
        break;
      default:
        hotspot_type = "point";
    }
    
  return {
    hotspot_type,
    hotspot_coordinates: coordinates,
    panorama,
    yaw: coordinates[0].yaw,
    pitch: coordinates[0].pitch,
  };
};

export const formatPanoLinks = (input: EditorPanoLinkRaw[]): EditorPanoLink[] =>
  input.map(formatPanoLink);

export const formatPanoPoi = ({
  hotspot_type,
  hotspot_coordinates,
  poi,
  yaw,
  pitch,
}: EditorPanoPoiRaw): EditorPanoPoi => {
  yaw = formatCoordinate(yaw);
  pitch = formatCoordinate(pitch);
  const coordinates = hotspot_coordinates
    ? hotspot_coordinates.map(({ pitch: p, yaw: y }) => ({
        pitch: formatCoordinate(p),
        yaw: formatCoordinate(y),
      }))
    : [{ pitch, yaw }];

    switch(true) {
      case coordinates.length >= 2 && hotspot_type === "polyline":
        hotspot_type = "polyline";
        break;
      case coordinates.length >= 2 && hotspot_type === "polygon":
        hotspot_type = "polygon";
        break;
      default:
        hotspot_type = "point";
    }

  return {
    hotspot_type,
    hotspot_coordinates: coordinates,
    poi,
    yaw: coordinates[0].yaw,
    pitch: coordinates[0].pitch,
  };
};

export const formatPanoPois = (input: EditorPanoPoiRaw[]): EditorPanoPoi[] =>
  input.map(formatPanoPoi);

export const formatMapPano = ({ title, pano, lat, long, color }: any): EditorMapPano => {
  pano = Number(pano);
  lat = Number(lat);
  long = Number(long);
  if (typeof color !== 'string') {
    throw new Error('Invalid input format for color');
  }
  if (typeof title !== 'string') {
    throw new Error('Invalid input format for title');
  }
  return { title, pano, lat, long, color };
};

export const formatMapPoi = ({ title, poi, lat, long, color }: any): EditorMapPoi => {
  poi = Number(poi);
  lat = Number(lat);
  long = Number(long);
  if (typeof color !== 'string') {
    throw new Error('Invalid input format for color');
  }
  if (typeof title !== 'string') {
    throw new Error('Invalid input format for title');
  }
  return { title, poi, lat, long, color };
};

export const formatMapPanos = (input: Object[]): EditorMapPano[] =>
  input.map(formatMapPano);

export const formatMapPois = (input: Object[]): EditorMapPoi[] =>
  input.map(formatMapPoi);

export const areNumbersEqual = (num1: number, num2: number, epsilon = 0.01) => {
  return Math.abs(num1 - num2) < epsilon;
}

export const areCoordinatesEqual = (coords1: { pitch: number; yaw: number }[], coords2: { pitch: number; yaw: number }[], epsilon = 0.01): boolean => {
  if (!Array.isArray(coords1) || !Array.isArray(coords2)) return false;
  if (coords1.length !== coords2.length) return false;
  return coords1.every((coord, index) => 
    areNumbersEqual(coord.pitch, coords2[index].pitch, epsilon) &&
    areNumbersEqual(coord.yaw, coords2[index].yaw, epsilon)
  );
}

export enum Picking {
  Off,
  Coordinate,
  Pano,
  Poi,
}

export interface SelectedLink {
  type: string;
  item: number;
}

interface EditorPanoLinkRaw {
  hotspot_type: keyof PanoHotspotTypeMap;
  hotspot_coordinates: false | { pitch: number | string; yaw: number | string }[];
  panorama: number;
  yaw: string | number;
  pitch: string | number;
}

interface EditorPanoPoiRaw {
  hotspot_type: keyof PanoHotspotTypeMap;
  hotspot_coordinates: false | { pitch: number | string; yaw: number | string }[];
  poi: number;
  yaw: string | number;
  pitch: string | number;
}

export interface EditorPanoLink extends EditorPanoLinkRaw {
  hotspot_coordinates: { pitch: number; yaw: number }[];
  yaw: number;
  pitch: number;
}

export interface EditorPanoPoi extends EditorPanoPoiRaw {
  hotspot_coordinates: { pitch: number; yaw: number }[];
  yaw: number;
  pitch: number;
}

export interface EditorPanoLink extends EditorPanoLinkRaw {
  hotspot_coordinates: { pitch: number; yaw: number }[];
  yaw: number;
  pitch: number;
}

export interface BasicEditorMapPano {
  title: string;
  pano: number;
}

export interface BasicEditorMapPoi {
  title: string;
  poi: number;
}

export interface EditorMapPano extends BasicEditorMapPano {
  lat: number;
  long: number;
  color: string;
}

export interface EditorMapPoi extends BasicEditorMapPoi {
  lat: number;
  long: number;
  color: string;
}

export interface EditorDataMap {
  pano: {
    panorama_location: EditorPanoLink[];
    poi_location: EditorPanoPoi[];
  };
  map: {
    panorama_location: EditorMapPano[];
    unlinked_panorama_location: EditorMapPano[];
    unused_panorama_location: BasicEditorMapPano[];
    poi_location: EditorMapPoi[];
    unlinked_poi_location: EditorMapPoi[];
    unused_poi_location: BasicEditorMapPoi[];
  };
}

export type EditorData<T extends keyof EditorDataMap = keyof EditorDataMap> = EditorDataMap[T];