import React from "react";
import { StyledNavigationMobile } from "./NavigationMobileStyles";
import ActionList from "../../shared/components/ActionList";
import Action from "../../shared/components/Action";
import { ReactComponent as NavigationMenuIcon } from "./../../images/navigationMenu.svg";
import { ReactComponent as EditorIcon } from "./../../images/editor-icons/pencil.svg";
import { useLocation } from "react-router-dom";
import { useInterfaceState } from "../../InterfaceContext";
import { messages } from "../../global-intl-messages";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Capability, useCapability } from "../../hooks/useCapability";
import { useAppState } from "../../AppContext";

export default function NavigationMobile() {
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const { pathname } = useLocation();
  const participating = pathname.includes("participation");
  const tour = pathname.includes("tour");
  const question = pathname.includes("question");
  const history = useHistory();
  const { state } = useAppState();
  const editPermission = useCapability(Capability.EditSep); 

  return (
    <StyledNavigationMobile>
      {!participating && !tour && !question && (
        <ActionList>
          <Action
            icon={<NavigationMenuIcon />}
            onClick={() => {
              setInterfaceState({
                ...interfaceState,
                sidebarOpen: !interfaceState.sidebarOpen,
              });
            }}
          >
            <FormattedMessage {...messages.menuButton} />
          </Action>
          {editPermission && !state.initializing && (
            <Action icon={<EditorIcon />} onClick={() => history.push("/editor")}>
              <FormattedMessage
                id="editor.start-editing-button"
                defaultMessage="Edit content"
                description="The label for the button which activates the editor."
              />
            </Action>
          )}
        </ActionList>
      )}
    </StyledNavigationMobile>
  );
}
