import { useMemo } from "react";
import classNames from "classnames";
import { ScenarioSwitchContainer, ScenarioLink, ScenarioLinkList } from "../../Panorama/PanoStyles";
import { LayerGroup, LayerGroupScenario, Pano, PanoScenario } from "../../../types";
import { useAppState } from "../../../AppContext";
import { useHistory } from "react-router-dom";

type EditorScenarioSwitchProps = {
  item: Pano | LayerGroup;
  selectedScenario: PanoScenario | LayerGroupScenario;
};

export function EditorScenarioSwitch({ item, selectedScenario }: EditorScenarioSwitchProps) {
  const { state } = useAppState();
  const history = useHistory();
  
  const scenarioLinks = useMemo(() => {
    return (item.scenarios as (PanoScenario | LayerGroupScenario)[])
      .filter((scenario: PanoScenario | LayerGroupScenario) => scenario)
      .map((scenario: PanoScenario | LayerGroupScenario) => (
        <ScenarioLink
          key={scenario.slug}
          className={classNames({
            active:
              scenario.slug === selectedScenario.slug,
          })}
          onClick={() => {
            const path = 'fov' in item ? 'pano' : 'map';
            history.push(`/editor/${path}/${item.id}/${scenario.slug}`);
          }}
        >
          {state.scenarios.find((s) => s.slug === scenario.slug)?.title}
        </ScenarioLink>
      ));
  }, [history, item, selectedScenario.slug, state.scenarios]);

  if (scenarioLinks.length < 2) return null;

  return (
    <ScenarioSwitchContainer>
      <ScenarioLinkList>{scenarioLinks}</ScenarioLinkList>
    </ScenarioSwitchContainer>
  );
}
