import { AppState, StyleOverrides, UseStateReturn, WordpressBase } from "./types";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { generateConfigFromWordpress } from "./utils/configUtils";
import projects from "./dummyproject.json";
import { transformWordpressPages } from "./utils/pageUtils";
import { transformWordpressPanos } from "./utils/panoUtils";
import { transformWordpressPois } from "./utils/poiUtils";
import { transformWordpressParticipation } from "./utils/participationUtils";
import { transformWordpressScenarios } from "./utils/scenarioUtils";
import { transformWordpressLayerGroups } from "./utils/layerGroupUtils";
import { transformWordpressAreas } from "./utils/areaUtils";
import { transformWordpressTours } from "./utils/tourUtils";
import { transformWordpressFaqs } from "./utils/faqUtils";
import { transformWordpressMenu } from "./utils/menuUtils";
import { transformWordpressPhasings } from "./utils/phasingUtils";
import usePromise from "./hooks/usePromise";
import { Capability, useCapability } from "./hooks/useCapability";
import { useAuthenticatedState } from "./hooks/useAuthenticatedState";
import { Feature, useFeature } from "./hooks/useFeature";
import { getLocalStorage, setLocalStorage } from "./utils/corsUtils";
import useWordpressApi from "./hooks/useWordpressApi";
import TokenStyles from "./TokenStyles";
import { styleDefaults, transformWordpressStyles } from "./utils/styleUtils";

let origin = window.origin;

if (process.env.NODE_ENV === "development") {
  origin = process.env.REACT_APP_PROXY_HOST!;
}

const noop = () => {};

// @ts-ignore
const initialState: AppState = { initializing: true };

const StateContext = createContext<{
  state: AppState;
  showDraft: boolean;
  setShowDraft: UseStateReturn<boolean>[1];
  showFuture: boolean;
  setShowFuture: UseStateReturn<boolean>[1];
  showPending: boolean;
  setShowPending: UseStateReturn<boolean>[1];
  showPrivate: boolean;
  setShowPrivate: UseStateReturn<boolean>[1];
  refresh: () => void;
}>({
  state: initialState,
  showDraft: false,
  setShowDraft: noop,
  showFuture: false,
  setShowFuture: noop,
  showPending: false,
  setShowPending: noop,
  showPrivate: false,
  setShowPrivate: noop,
  refresh: noop,
});

const currentLastVisit = getLocalStorage("lastVisit");

export const StateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, setState] = useState(initialState);
  const canReadDraft = useCapability(Capability.ReadDraft);
  const [showDraft, setShowDraft] = useAuthenticatedState(false, Capability.ReadDraft);
  const [showFuture, setShowFuture] = useAuthenticatedState(false, Capability.ReadDraft);
  const [showPending, setShowPending] = useAuthenticatedState(false, Capability.ReadDraft);
  const [showPrivate, setShowPrivate] = useAuthenticatedState(false, Capability.ReadDraft);
  const participationAvailable = useFeature(Feature.Participation);
  const faqsAvailable = useFeature(Feature.Faqs);
  const phasingAvailable = useFeature(Feature.Phasing);
  const brandingAvailable = useFeature(Feature.Branding);
  const locationQuery = window.location.search;
  const [importedFonts, setImportedFonts] = useState(false);
  const [refreshToken, setRefreshToken] = useState(1);

  const [lastVisit] = usePromise(async () => {
    const lv = await currentLastVisit;
    await setLocalStorage("lastVisit", new Date().toISOString());
    return lv ? new Date(lv) : null;
  }, []);

  const refresh = useCallback(() => setRefreshToken((token) => ++token), [setRefreshToken]);

  const previewMode = useMemo(() => {
    const search = new URLSearchParams(locationQuery);
    return search.has("preview");
  }, [locationQuery]);

  useEffect(() => {
    setShowDraft(previewMode);
  }, [setShowDraft, previewMode]);

  const filter = useMemo(() => {
    const filter = ["publish"];
    if (showDraft) filter.push("draft");
    if (showFuture) filter.push("future");
    if (showPending) filter.push("pending");
    if (showPrivate) filter.push("private");
    return filter;
  }, [showDraft, showFuture, showPending, showPrivate]);

  const contentFilter = useCallback(
    ({ status }: { status: WordpressBase["status"] }) => filter.includes(status),
    [filter]
  );

  const query = useMemo(
    () => ({
      per_page: 100,
      status: canReadDraft ? "any" : "publish",
    }),
    [canReadDraft]
  );

  const wordpressApi = useWordpressApi(`${origin}/wp-json`);

  const [siteConfigRaw, siteConfigError] = usePromise(
    () => wordpressApi.config().then((res) => res.data),
    [refreshToken]
  );

  const [poisRaw, poisError] = usePromise(
    () => wordpressApi.pois.list(query).then((res) => res.data),
    [wordpressApi.pois, query, refreshToken]
  );

  const [panosRaw, panosError] = usePromise(
    () => wordpressApi.panos.list(query).then((res) => res.data),
    [wordpressApi.panos, query, refreshToken]
  );

  const [phasingRaw, phasingError] = usePromise(
    () =>
      phasingAvailable
        ? wordpressApi.phasing.list(query).then((res) => res.data)
        : Promise.resolve(undefined),
    [phasingAvailable, wordpressApi.phasing, query, refreshToken]
  );

  const [pagesRaw, pagesError] = usePromise(
    () => wordpressApi.pages.list(query).then((res) => res.data),
    [wordpressApi.pages, query, refreshToken]
  );

  const [layerGroupsRaw, layerGroupsError] = usePromise(
    () => wordpressApi.layerGroups.list(query).then((res) => res.data),
    [wordpressApi.layerGroups, query, refreshToken]
  );

  const [participationRaw, participationError] = usePromise(
    () =>
      participationAvailable
        ? wordpressApi.participation.list(query).then((res) => res.data)
        : Promise.resolve(undefined),
    [participationAvailable, wordpressApi.participation, query, refreshToken]
  );

  const [scenariosRaw, scenariosError] = usePromise(
    () => wordpressApi.scenarios.list(query).then((res) => res.data),
    [wordpressApi.scenarios, query, refreshToken]
  );

  const [areasRaw, areasError] = usePromise(
    () => wordpressApi.areas.list(query).then((res) => res.data),
    [wordpressApi.areas, query, refreshToken]
  );

  const [toursRaw, toursError] = usePromise(
    () => wordpressApi.tours.list(query).then((res) => res.data),
    [wordpressApi.tours, query, refreshToken]
  );

  const [faqRaw, faqError] = usePromise(
    () =>
      faqsAvailable
        ? wordpressApi.faqs.list(query).then((res) => res.data)
        : Promise.resolve(undefined),
    [faqsAvailable, wordpressApi.faqs, query, refreshToken]
  );

  const [menuRaw, menuError] = usePromise(
    () => wordpressApi.menu().then((res) => res.data),
    [wordpressApi.menu, refreshToken]
  );

  useEffect(() => {
    [
      siteConfigError,
      poisError,
      panosError,
      pagesError,
      phasingError,
      layerGroupsError,
      participationError,
      scenariosError,
      areasError,
      toursError,
      faqError,
      menuError,
    ].forEach((error) => {
      if (error) console.warn(error);
    });
  }, [
    siteConfigError,
    poisError,
    panosError,
    pagesError,
    phasingError,
    layerGroupsError,
    participationError,
    scenariosError,
    areasError,
    toursError,
    faqError,
    menuError,
  ]);

  const pages = useMemo(
    () => pagesRaw && transformWordpressPages(pagesRaw.filter(contentFilter)),
    [pagesRaw, contentFilter]
  );

  const phasing = useMemo(
    () => phasingRaw && transformWordpressPhasings(phasingRaw.filter(contentFilter)),
    [phasingRaw, contentFilter]
  );

  const scenarios = useMemo(
    () => scenariosRaw && transformWordpressScenarios(scenariosRaw.filter(contentFilter)),
    [scenariosRaw, contentFilter]
  );

  const layerGroups = useMemo(
    () =>
      layerGroupsRaw &&
      scenarios &&
      transformWordpressLayerGroups(layerGroupsRaw.filter(contentFilter), scenarios),
    [layerGroupsRaw, scenarios, contentFilter]
  );

  const panos = useMemo(
    () =>
      panosRaw &&
      scenarios &&
      layerGroups &&
      transformWordpressPanos(panosRaw.filter(contentFilter), layerGroups, scenarios),
    [panosRaw, scenarios, layerGroups, contentFilter]
  );

  const pois = useMemo(
    () =>
      poisRaw &&
      layerGroups &&
      panos &&
      transformWordpressPois(poisRaw.filter(contentFilter), layerGroups, panos, scenarios ? scenarios : [], lastVisit),
    [poisRaw, layerGroups, panos, contentFilter, scenarios, lastVisit]
  );

  const participation = useMemo(
    () =>
      participationRaw &&
      layerGroups &&
      panos &&
      transformWordpressParticipation(participationRaw.filter(contentFilter), layerGroups, panos),
    [participationRaw, layerGroups, panos, contentFilter]
  );

  const faqs = useMemo(
    () =>
      faqRaw &&
      layerGroups &&
      panos &&
      scenarios &&
      transformWordpressFaqs(faqRaw.filter(contentFilter), layerGroups, panos, scenarios),
    [faqRaw, layerGroups, panos, scenarios, contentFilter]
  );

  const areas = useMemo(
    () =>
      areasRaw &&
      layerGroups &&
      transformWordpressAreas(areasRaw.filter(contentFilter), layerGroups),
    [areasRaw, layerGroups, contentFilter]
  );

  const { tours, tourPoints } = useMemo(() => {
    if (toursRaw && layerGroups && panos && scenarios) {
      return transformWordpressTours(toursRaw.filter(contentFilter), layerGroups, panos, scenarios);
    }
    return { tours: undefined, tourPoints: undefined };
  }, [toursRaw, layerGroups, panos, scenarios, contentFilter]);

  const config = useMemo(
    () =>
      siteConfigRaw &&
      scenarios &&
      pois &&
      panos &&
      pages &&
      tours &&
      generateConfigFromWordpress(siteConfigRaw, scenarios, panos, pages),
    [siteConfigRaw, scenarios, panos, pages, pois, tours]
  );

  const menuItems = useMemo(
    () =>
      (menuRaw?.length && transformWordpressMenu(menuRaw).filter(contentFilter)) ||
      (config && config.menuItems),
    [menuRaw, config, contentFilter]
  );

  const styleOverrides = useMemo<StyleOverrides>(
    () => transformWordpressStyles(siteConfigRaw),
    [siteConfigRaw]
  );

  useEffect(() => {
    if (!brandingAvailable || !siteConfigRaw) return;

    if (siteConfigRaw?.acf.font_import_urls) {
      siteConfigRaw?.acf.font_import_urls?.forEach(({url}) => {
        if (Array.from(document.styleSheets).some((sheet) => sheet.href === url)) return;
  
        const link = document.createElement("link");
        link.href = url;
        link.rel = "stylesheet";
        document.head.appendChild(link);
      });
    }

    setImportedFonts(true);
  }, [siteConfigRaw, brandingAvailable])

  const defaultLayerGroupSlug = useMemo(() => layerGroups?.[0]?.slug || "", [layerGroups]);

  useEffect(() => {
    if (config) {
      setState({
        ...config,
        initializing: false,
        pois: pois || [],
        panos: panos || [],
        pages: pages || [],
        areas: areas || [],
        faqs: faqs || [],
        tours: tours || [],
        tourPoints: tourPoints || [],
        phasing: phasing || [],
        projects,
        scenarios: scenarios || [],
        participation: participation || [],
        map: {
          ...config.mapConfig,
          baseLayers: [],
          layerGroups: layerGroups || [],
          defaultLayerGroupSlug,
        },
        menuItems: menuItems || [],
      });
    }
  }, [
    config,
    pois,
    panos,
    pages,
    phasing,
    areas,
    faqs,
    tours,
    tourPoints,
    scenarios,
    participation,
    layerGroups,
    defaultLayerGroupSlug,
    menuItems,
  ]);

  return (
    <StateContext.Provider
      value={{
        state,
        showDraft,
        setShowDraft,
        showFuture,
        setShowFuture,
        showPending,
        setShowPending,
        showPrivate,
        setShowPrivate,
        refresh,
      }}
    >
      {brandingAvailable && importedFonts ? <TokenStyles overrides={styleOverrides} /> : <TokenStyles overrides={styleDefaults} />}
      {children}
    </StateContext.Provider>
  );
};

export const useAppState = () => useContext(StateContext);
