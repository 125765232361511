import React, { ReactNode, useState } from "react";
import { KrpanoElement } from "../../../libs/krpano";
import { Button, ConfirmDialogWrapper, ConfirmDialogDialog } from "../EditorStyles";
import { FormattedMessage } from "react-intl";
import SpinnerWrapper from "./SpinnerWrapper";

interface ConfirmDialogProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  onConfirm: () => Promise<void>;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ show, setShow, children, onConfirm }) => {
  const [loading, setLoading] = useState<boolean>(false);
  
  const close = () => {
    setShow(false);
  };

  const confirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  if (show) {
    return (
      <ConfirmDialogWrapper>
        <ConfirmDialogDialog>
          <SpinnerWrapper loading={loading}>
            {children}
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px", paddingTop: "8px" }}
            >
              <Button onClick={close}>
                <FormattedMessage
                  id="editor.link.cancel"
                  defaultMessage="Cancel"
                  description="Text for cancel button"
                />
              </Button>
              <Button className="primary" onClick={confirm}>
                <FormattedMessage
                  id="editor.link.confirm"
                  defaultMessage="Confirm"
                  description="Text for confirm button"
                />
              </Button>
            </div>
          </SpinnerWrapper>
        </ConfirmDialogDialog>
      </ConfirmDialogWrapper>
    );
  }

  return null;
};

export default ConfirmDialog;
