import { AxiosRequestConfig } from "axios";
import { Repository } from "./Repository";
import { BasicEditorMapPano, EditorMapPano, formatMapPanos } from "../../../utils/editorUtils";

export class MapPanoRepository extends Repository {
  async get(restAuth: AxiosRequestConfig | undefined, map?: number): Promise<EditorMapPano[]> {
    const data = await this.api.get(
      restAuth,
      map ? `/sep_edit/v1/map/${map}/map_pano?context=edit` : "/sep_edit/v1/map/map_pano?context=edit",
    );

    if (data) {
      return formatMapPanos(data);
    }
    return [];
  }

  async get_unlinked(restAuth: AxiosRequestConfig | undefined, map: number): Promise<EditorMapPano[]> {
    const data = await this.api.get(
      restAuth,
      `/sep_edit/v1/map/${map}/map_pano/unlinked?context=edit`,
    );

    if (data) {
      return formatMapPanos(data);
    }
    return [];
  }

  async get_unused(restAuth: AxiosRequestConfig | undefined): Promise<BasicEditorMapPano[]> {
    const data = await this.api.get(
      restAuth,
      "/sep_edit/v1/map/map_pano/unlinked?context=edit",
    );

    if (data) {
      return data;
    }
    return [];
  }

  async create(restAuth: AxiosRequestConfig | undefined, item: {lat?: number, long?: number, color?: string} | {pano: number}, map?: number): Promise<EditorMapPano[]> {
    const data = await this.api.create(
      restAuth,
      map ? `/sep_edit/v1/map/${map}/map_pano` : "/sep_edit/v1/map/map_pano",
      item,
    );

    if (data) {
      return formatMapPanos(data);
    }
    return [];
  }

  async update(restAuth: AxiosRequestConfig | undefined, item: {lat?: number, long?: number, color?: string, pano?: number}, pano: number): Promise<EditorMapPano[]> {
    const data = await this.api.update(
      restAuth,
      `/sep_edit/v1/map/map_pano/${pano}`,
      item,
    );
    if (data) {
      return formatMapPanos(data);
    }
    return [];
  }

  async delete(restAuth: AxiosRequestConfig | undefined, pano: number, map?: number): Promise<EditorMapPano[]> {
    const data = await this.api.delete(
      restAuth,
      map ? `/sep_edit/v1/map/${map}/map_pano/${pano}` : `/sep_edit/v1/map/map_pano/${pano}`,
    );

    if (data) {
      return formatMapPanos(data);
    }
    return [];
  }
}