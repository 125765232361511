import React from "react";
import { ReactComponent as Spinner } from "./../../../images/spinner.svg";

interface SpinnerWrapperProps {
  loading: boolean;
  children: React.ReactNode;
}

const SpinnerWrapper: React.FC<SpinnerWrapperProps> = ({ loading, children }) => {
  if (loading) {
    return (
      <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Spinner/>
      </div>
    );
  }
  return <>{children}</>;
};

export default SpinnerWrapper;