import { Button, LinkAdderPlaceholder } from "../../EditorStyles";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ClickIcon } from "./../../../../images/editor-icons/mouse-pointer-click.svg";
import { Picking } from "../../../../utils/editorUtils";

interface PanoPickerPlaceholderProps {
  setPicking: React.Dispatch<React.SetStateAction<Picking>>;
}

const PanoPickerPlaceholder: React.FC<PanoPickerPlaceholderProps> = ({ setPicking }) => {
    return (
        <>
        <LinkAdderPlaceholder>
          <h2>
            <ClickIcon style={{marginRight: "6px", position: "relative", top: "3px"}}/>
            <FormattedMessage
              id="editor.panos.link.picking_placeholder_heading"
              defaultMessage="Select a location"
              description="Placeholder text heading when creating a new link on a panorama"
            />
          </h2>
          <p>
            <FormattedMessage
              id="editor.panos.link.picking_placeholder_text"
              defaultMessage="Click on your desired location on the panorama to add a new link or coordinate."
              description="Placeholder text when creating a new link on a panorama"
            />
          </p>
          <Button onClick={() => setPicking(Picking.Off)}>
            <FormattedMessage
              id="editor.panos.link.cancel"
              defaultMessage="Stop selecting"
              description="Text for exit picking mode button on a panorama"
            />
          </Button>
        </LinkAdderPlaceholder>
      </>
    );
};

export default PanoPickerPlaceholder;
