import styled from "styled-components";
import SharedButtonComponent from "../../shared/components/Button";

export const Label = styled.label`
  font-size: 13px;
  display: block;
  font-family: var(--font-family-heading);
`;

export const CoordinateInput = styled.div`
  display: flex;
  column-gap: var(--space-10);
`;

export const EditorBlock = styled.div`
  margin-bottom: var(--space-20);
`;

export const Input = styled.input`
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral-10);
    border-radius: var(--border-radius-default);
    color: inherit;
    display: block;
    font-family: var(--font-family-body);
    font-size: var(--font-size-30);
    margin: 0;
    padding: 6px 12px;
    width: 100%;
    transition: box-shadow 150ms var(--bezier-a);

    &:focus {
      background-color: var(--color-white);
      border: 1px solid var(--color-primary-50);
      box-shadow: 0px 0px 0px 3px var(--color-primary-20);
      outline: 0;
      color: var(--color-neutral-90);
      position: relative;
    }

    &:disabled {
      background: var(--color-neutral-10);
      color: var(--color-neutral-50);
    }
`;

export const Select = styled.select`
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral-10);
    border-radius: var(--border-radius-default);
    color: inherit;
    display: block;
    font-family: var(--font-family-body);
    font-size: var(--font-size-30);
    margin: 0;
    padding: 6px 12px;
    width: 100%;
    transition: box-shadow 150ms var(--bezier-a);

    &:focus {
      background-color: var(--color-white);
      border: 1px solid var(--color-primary-50);
      box-shadow: 0px 0px 0px 3px var(--color-primary-20);
      outline: 0;
      color: var(--color-neutral-90);
      position: relative;
    }

    &:disabled {
      background: var(--color-neutral-10);
      color: var(--color-neutral-50);
    }
`;

export const Hr = styled.hr`
  margin: var(--space-30) 0;
  border-width: 2px 0 0;
`;

export const Warning = styled.p`
  padding: var(--space-20);
  margin-bottom: var(--space-10);
  background: var(--color-error-30);
  border-radius: var(--border-radius-10);
  border: solid 1px var(--color-error-70);
  color: var(--color-error-70);
`;

















export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const NavSidebar = styled.div`
  width: 70px;
  background: var(--color-white);
  border-right: 1px solid var(--color-neutral-20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavButtonList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavButton = styled.button<{ $active?: boolean }>`
  height: 70px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  background: ${props => props.$active ? 'var(--color-neutral-20)' : 'transparent'};
  cursor: pointer;
  color: ${props => props.$active ? 'var(--color-primary-50)' : 'var(--color-neutral-90)'};
  text-decoration: none;

  &:hover {
    color: var(--color-neutral-90);
    background: var(--color-neutral-10);
  }

  p {
    margin: 0;
    font-size: 11px;
    font-family: var(--font-family-heading);
    font-weight: var(--font-weight-bold);
  }
`;

export const NavButtonAction = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary-50);
  color: var(--color-white);	
  border: none;
  cursor: pointer;
`;

export const MainSidebar = styled.div`
  width: 400px;
  background: var(--color-neutral-10);
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

export const OverviewPanel = styled.div`
  padding: 16px;
`;

export const SidebarList = styled.h1`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PageTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
`;

export const PageTitleWrapper = styled.div`
  margin: 18px 0;
  display: flex;
  justify-content: space-between;
`;

export const SidebarListItem = styled.button`
  width: 100%;
  padding: 12px 16px;
  border-radius: 100px;
  text-align: left;
  background: var(--color-white);
  border: 1px solid var(--color-neutral-20);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  flex-grow: 1;
`;

export const Popup = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  bottom: 8px;
  left: 68px;
  background: var(--color-white);
  border: 1px solid var(--color-neutral-10);
  border-radius: 6px;
  padding: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: ${props => props.$isOpen ? 'flex' : 'none'};
  width: 280px;
  overflow: hidden;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
`;

export const MainContentPlaceholderWrapper = styled.div`
  padding: 60px;
`;

export const SideBarTopActionButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const LinkEditorDialog = styled.div`
  position: absolute;
  width: 320px;
  left: 0;
  top: 0;
  padding: 20px 16px;
  margin: 20px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
`;

export const TabContainer = styled.div`
  display: flex;
  margin: auto;
  gap: 2px;
`;

export const Tab = styled.button<{ $active?: boolean }>`
  background-color: ${props => props.$active ? 'var(--color-primary-20)' : 'transparent'};
  color: ${props => props.$active ? 'var(--color-primary-50)' : 'var(--color-neutral-70)'};
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 16px;
  width: 50%;
  font-family: var(--font-family-heading);

  &:hover {
    background-color: ${props => props.$active ? 'var(--color-primary-20)' : 'var(--color-neutral-10)'};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
`;

export const CoordinateInputGroup = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 10px;

  &:hover {
    background: var(--color-neutral-20);
  }
`;

export const Button = styled(SharedButtonComponent)`
  display: flex;
  box-shadow: var(--box-shadow-20);
  align-items: center;
  gap: 8px;

  svg {
    height: 18px;
    width: 18px
  }
`;

export const StackedItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  div {
    width: 100%;
  }
`;

export const ConfirmDialogWrapper = styled.div`
  z-index: 15;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmDialogDialog = styled.div`
  width: 320px;
  text-align: center;
  padding: 30px 24px;
  margin: 20px;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
`;

export const LinkAdderPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;


export const TooltipWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  
  > button {
    width: 100%;
  }
`;

export const TooltipTip = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: nowrap;
  width: auto !important;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0,0,0,0.8) transparent transparent transparent;
  }
`;

export const MapPointLabel = styled.p<{ $disabled?: boolean, $circle?: boolean }>`
  opacity: ${props => props.$disabled ? '0.4' : '1'};
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-left: ${props => props.$circle ? '-2px' : '-8px'};
  margin-top: ${props => props.$circle ? '10px' : '4px'};
  -webkit-text-stroke: 0.3px #222;
  text-shadow: 
    0px 0px 1px black,
    0px 0px 1px black,
    0px 0px 1px rgb(0, 0, 0);
    0px 0px 1px rgb(0, 0, 0);
    0px 0px 2px rgba(0, 0, 0, 0.6);
    0px 0px 2px rgba(0, 0, 0, 0.6);
`;

export const InlinePopupContainer = styled.div`
  position: absolute;
  left: 50%;
  width: 380px;
  transform: translateX(-50%);
  margin-top: 8px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 6px;

  h3 {
    font-size: 15px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;