import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useEditState } from "../../EditContext";
import {
  CoordinateInputGroup,
  IconButton,
  Input,
} from "../../EditorStyles";
import { ReactComponent as TrashIcon } from "./../../../../images/editor-icons/trash.svg";
import ConfirmDialog from "../../BaseComponents/ConfirmDialog";
import { useRestAuth } from "../../../../hooks/useRestAuth";
import { MarkerTypes } from "../../../../shared/components/MarkerImages";
import { getPoiIcon } from "../../../../utils/panoUtils";

interface PanoLinkPointProps {
  deletable?: boolean;
  selectedLink: { type: string; item: number };
  pointIndex: number;
  coordinates: { yaw: number; pitch: number }[];
}

interface BasicEditingLink {
  yaw?: number;
  pitch?: number;
  hotspot_coordinates: { yaw: number; pitch: number }[];
}

export function CoordinateEditor({
  deletable = false,
  selectedLink,
  pointIndex,
  coordinates,
}: PanoLinkPointProps) {
  const { panoLinkRepository, panoPoiRepository, editData, target } = useEditState();
  const [deleteCoordinate, setDeleteCoordinate] = useState(false);
  const [yaw, setYawValue] = useState(coordinates[pointIndex]?.yaw.toString() || "0");
  const [pitch, setPitchValue] = useState(coordinates[pointIndex]?.pitch.toString() || "0");
  const restAuth = useRestAuth();

  useEffect(() => {
    setYawValue(coordinates[pointIndex]?.yaw.toString() || "0");
    setPitchValue(coordinates[pointIndex]?.pitch.toString() || "0");
  }, [coordinates, pointIndex]);

  const setYaw = useCallback(
    async (newYaw: number) => {
      if (target) {
        let editedCoordinates = coordinates.slice();
        editedCoordinates[pointIndex] = { yaw: newYaw, pitch: coordinates[pointIndex].pitch };
        const link: BasicEditingLink = { hotspot_coordinates: editedCoordinates };
        if (pointIndex === 0) {
          link.yaw = editedCoordinates[0].yaw;
        }

        if (selectedLink.type === "pano") {
          const links = await panoLinkRepository.update(restAuth, link, selectedLink.item, target);
          editData<"pano">({ panorama_location: links });
        } else {
          const links = await panoPoiRepository.update(restAuth, link, selectedLink.item, target);
          editData<"pano">({ poi_location: links });
        }
      }
    },
    [
      coordinates,
      editData,
      panoLinkRepository,
      panoPoiRepository,
      pointIndex,
      restAuth,
      selectedLink,
      target,
    ]
  );

  const setPitch = useCallback(
    async (newPitch: number) => {
      if (target) {
        let editedCoordinates = coordinates.slice();
        editedCoordinates[pointIndex] = { yaw: coordinates[pointIndex].yaw, pitch: newPitch };
        const link: BasicEditingLink = { hotspot_coordinates: editedCoordinates };
        if (pointIndex === 0) {
          link.pitch = editedCoordinates[0].pitch;
        }

        if (selectedLink.type === "pano") {
          const links = await panoLinkRepository.update(restAuth, link, selectedLink.item, target);
          editData<"pano">({ panorama_location: links });
        } else {
          const links = await panoPoiRepository.update(restAuth, link, selectedLink.item, target);
          editData<"pano">({ poi_location: links });
        }
      }
    },
    [
      coordinates,
      editData,
      panoLinkRepository,
      panoPoiRepository,
      pointIndex,
      restAuth,
      selectedLink,
      target,
    ]
  );

  const onDeleteCoordinate = useCallback(async () => {
    if (deletable && target) {
      const editedCoordinates = coordinates.slice();
      editedCoordinates.splice(pointIndex, 1);
      const link: BasicEditingLink = { hotspot_coordinates: editedCoordinates };
      if (pointIndex === 0) {
        link.yaw = editedCoordinates[0].yaw;
        link.pitch = editedCoordinates[0].pitch;
      }

      if (selectedLink.type === "pano") {
        const links = await panoLinkRepository.update(restAuth, link, selectedLink.item, target);
        editData<"pano">({ panorama_location: links });
      } else {
        const links = await panoPoiRepository.update(restAuth, link, selectedLink.item, target);
        editData<"pano">({ poi_location: links });
      }
      setDeleteCoordinate(false);
    }
  }, [
    pointIndex,
    deletable,
    target,
    coordinates,
    selectedLink.type,
    selectedLink.item,
    panoLinkRepository,
    restAuth,
    editData,
    panoPoiRepository,
  ]);

  const icon = useMemo(() => {
    let vertexColor = "--color-neutral-50";
    if (!pointIndex) vertexColor = "--color-success-50";
    if (pointIndex === coordinates.length - 1) vertexColor = "--color-neutral-70";

    return "data:image/svg+xml;utf8," + encodeURIComponent(getPoiIcon(MarkerTypes.Edit, vertexColor, (pointIndex + 1).toString()));
  }, [coordinates.length, pointIndex]);

  return (
    <CoordinateInputGroup>
      { coordinates.length > 1 && 
        <img src={icon} alt="Icon" style={{height: "22px", width: "22px", marginRight: "6px"}}/>
      }
      <Input
        type="text"
        placeholder="Pan"
        value={yaw}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          if (/^[\d.-]*$/.test(value)) {
            setYawValue(value);
          }
        }}
        onBlur={() => {
          const formattedValue = yaw.replace(",", "");
          if (/^-?\d*\.?\d*$/.test(formattedValue)) {
            setYaw(parseFloat(formattedValue));
          } else {
            setYawValue(coordinates[pointIndex]?.yaw.toString() || "0");
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            const formattedValue = yaw.replace(",", "");
            if (/^-?\d*\.?\d*$/.test(formattedValue)) {
              setYaw(parseFloat(formattedValue));
            } else {
              setYawValue(coordinates[pointIndex]?.yaw.toString());
            }
          }
        }}
      />

      <Input
        type="text"
        placeholder="Tilt"
        value={pitch}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          // Verwijder komma's uit de invoer
          if (/^[\d.-]*$/.test(value)) {
            setPitchValue(value);
          }
        }}
        onBlur={() => {
          const formattedValue = pitch.replace(",", "");
          if (/^-?\d*\.?\d*$/.test(formattedValue)) {
            setPitch(parseFloat(formattedValue));
          } else {
            setPitchValue(coordinates[pointIndex]?.pitch.toString() || "");
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            const formattedValue = pitch.replace(",", "");
            if (/^-?\d*\.?\d*$/.test(formattedValue)) {
              setPitch(parseFloat(formattedValue));
            } else {
              setPitchValue(coordinates[pointIndex]?.pitch.toString() || "");
            }
          }
        }}
      />
      {deletable && (
        <>
          <IconButton onClick={() => setDeleteCoordinate(true)}>
            <TrashIcon />
          </IconButton>
          <ConfirmDialog
            show={deleteCoordinate}
            setShow={setDeleteCoordinate}
            onConfirm={onDeleteCoordinate}
          >
            <h2>
              <FormattedMessage
                id="editor.coordinate.confirm_delete"
                defaultMessage="Are you sure you want to delete this coordinate?"
                description="Confirmation message for deleting a coordinate"
              />
            </h2>
          </ConfirmDialog>
        </>
      )}
    </CoordinateInputGroup>
  );
}
