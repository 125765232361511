import { useEffect, useRef, useState } from "react";
import {
  Container,
  NavSidebar,
  NavButton,
  NavButtonAction,
  Popup,
  NavButtonList,
  Button,
} from "./EditorStyles";
import { ReactComponent as CloseIcon } from "./../../images/editor-icons/x.svg";
import { ReactComponent as PanoramaIcon } from "./../../images/editor-icons/camera.svg";
import { ReactComponent as MapIcon } from "./../../images/editor-icons/map.svg";
import { ReactComponent as PoiIcon } from "./../../images/editor-icons/map-pin.svg";
import { ReactComponent as PublishIcon } from "./../../images/editor-icons/rocket.svg";
import { ReactComponent as DiscardIcon } from "./../../images/editor-icons/trash.svg";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import MapPage from "./Map/MapPage";
import PanoPage from "./Pano/PanoPage";
import { FormattedMessage } from "react-intl";
import { useEditState } from "./EditContext";
import ConfirmDialog from "./BaseComponents/ConfirmDialog";
import { useRestAuth } from "../../hooks/useRestAuth";
import { WordPressApi } from "./Repositories/WordPressApi";
import { Route, Link } from "react-router-dom";
import { useAppState } from "../../AppContext";

const Editor = () => {
  const history = useHistory();
  const { refresh } = useAppState();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [publishChanges, setPublishChanges] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { panoLinkRepository, panoPoiRepository, mapPanoRepository, mapPoiRepository, target, editData } = useEditState();
  const restAuth = useRestAuth();
  const api = WordPressApi.getInstance();

  const refreshAllData = async () => {
      if (restAuth) {
        if (location.pathname.startsWith("/editor/pano") && target) {
          editData<"pano">({
            panorama_location: await panoLinkRepository.get(restAuth, target),
            poi_location: await panoPoiRepository.get(restAuth, target),
          });
        }
        else if (location.pathname.startsWith("/editor/map")) {
          if (target) {
            editData<"map">({
              panorama_location: await mapPanoRepository.get(restAuth, target),
              poi_location: await mapPoiRepository.get(restAuth, target),
              unlinked_panorama_location: await mapPanoRepository.get_unlinked(restAuth, target),
              unlinked_poi_location: await mapPoiRepository.get_unlinked(restAuth, target),
            });
          } else {
            editData<"map">({
              panorama_location: await mapPanoRepository.get(restAuth),
              poi_location: await mapPoiRepository.get(restAuth, target),
              unused_panorama_location: await mapPanoRepository.get_unused(restAuth),
              unused_poi_location: await mapPoiRepository.get_unused(restAuth),
            });
          }
        }
      }
  };

  const onPublishChanges = async () => {
    await api.publish(restAuth);
    refresh();
    setPublishChanges(false);
  };

  const onDiscardChanges = async () => {
    await api.discard(restAuth);
    await refreshAllData();
    setDiscardChanges(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);


  return (
    <Container>
      <NavSidebar>
        <NavButtonList>
          <NavButton onClick={() => history.push("/")}>
            <NavButtonAction>
              <CloseIcon />
            </NavButtonAction>
          </NavButton>
          <NavButton as={Link} to="/editor/pano" $active={location.pathname.startsWith("/editor/pano")}>
            <PanoramaIcon />
            <p>
              <FormattedMessage
                id="editor.nav.panos"
                defaultMessage="Panoramas"
                description="Nav bar text for panoramas"
              />
            </p>
          </NavButton>
          <NavButton as={Link} to="/editor/map" $active={location.pathname.startsWith("/editor/map")}>
            <MapIcon />
            <p>
              <FormattedMessage
                id="editor.nav.maps"
                defaultMessage="Map"
                description="Nav bar text for map page"
              />
            </p>
          </NavButton>
        </NavButtonList>

        <NavButtonList>
          <NavButton onClick={() => setDiscardChanges(true)}>
            <DiscardIcon />
            <p>
              <FormattedMessage
                id="editor.publish.discard-button"
                defaultMessage="Discard all changes"
                description="Button text to discard all changes"
              />
            </p>
          </NavButton>
          <NavButton onClick={() => setPublishChanges(true)}>
            <PublishIcon />
            <p>
              <FormattedMessage
                id="editor.publish.publish-button"
                defaultMessage="Publish all changes"
                description="Button text to publish all changes"
              />
            </p>
          </NavButton>
        </NavButtonList>
      </NavSidebar>

      <ConfirmDialog
        show={discardChanges}
        setShow={setDiscardChanges}
        onConfirm={onDiscardChanges}
      >
        <h2>
          <FormattedMessage
            id="editor.publish.discard-popup-header"
            defaultMessage="Are you sure you want to discard the changes you made?"
            description="Header text form discard all changes popup dialog"
          />
        </h2>
        <p>
          <FormattedMessage
            id="editor.publish.discard-popup-body"
            defaultMessage="This action cannot be undone."
            description="Body text form discard all changes popup dialog"
          />
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        show={publishChanges}
        setShow={setPublishChanges}
        onConfirm={onPublishChanges}
      >
        <h2>
          <FormattedMessage
            id="editor.publish.publish-popup-header"
            defaultMessage="Are you sure you want to publish the changes you made?"
            description="Header text form publish all changes popup dialog"
          />
        </h2>
        <p>
          <FormattedMessage
            id="editor.publish.publish-popup-body"
            defaultMessage="This action cannot be undone."
            description="Body text form publish all changes popup dialog"
          />
        </p>
      </ConfirmDialog>

      <Switch>
        <Route exact path="/editor">
          <Redirect to="/editor/pano" />
        </Route>
        <Route path="/editor/pano" component={PanoPage} />
        <Route path="/editor/map" component={MapPage} />
        <Route path="/editor/poi" component={MapPage} />
      </Switch>
    </Container>
  );
};

export default Editor;
