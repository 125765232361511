import { AxiosRequestConfig } from "axios";
import { Pano } from "../../../types";
import { ICanRead } from "./ICanRead";
import { Repository } from "./Repository";

export class PanoRepository extends Repository implements ICanRead<Pano> {
  async get(restAuth: AxiosRequestConfig | undefined): Promise<Pano[]> {
    const data = await this.api.get(restAuth, "/wp/v2/pano");

    if (data) {
      const panos: Pano[] = data.map((item: any) => ({
        id: item.id,
        title: item.title.rendered,
      }));
      return panos;
    }

    return [];
  }

  async single(restAuth: AxiosRequestConfig | undefined, id: number): Promise<Pano> {
    const wp_data = await this.api.single(restAuth, `/wp/v2/pano/${id}`);
    const acf = wp_data.acf;

    if (wp_data && acf) {
      const pano: Pano = {
        id: id,
        title: wp_data.title.rendered,
        content: wp_data.content,
        excerpt: wp_data.excerpt,
        yaw: acf.yaw,
        pitch: acf.pitch,
        fov: acf.fov,
        slug: wp_data.slug,
        icon: acf.icon_type,
        type: acf.type,
        isIndoor: acf.isIndoor,
        showVideoControls: acf.video.show_controls,
        repeatVideo: acf.video.repeat,
        showInNavigation: acf.show_in_navigation,
        scenarios: acf.scenarios,
        featuredMedia: wp_data.featured_media,
        labels: acf.labels,
        status: acf.status,
        repeatAudio: acf.repeat_audio,
      };
      return pano;
    }

    return {} as Pano;
  }

  async update(item: Pano): Promise<Pano> {
    return item;
  }
}
