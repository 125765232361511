import React from "react";
import { SideBarTopActionButton, OverviewPanel, PageTitle } from "../EditorStyles";
import { FormattedMessage } from "react-intl";
import { Pano } from "../../../types";

interface PanoMetaDataEditorProps {
  pano: Pano | undefined;
}

const PanoMetaDataEditor: React.FC<PanoMetaDataEditorProps> = ({ pano }) =>  {
  return (
    // Hier komen metadata inputs
    <p></p>  );
};

export default PanoMetaDataEditor;
