import { AxiosRequestConfig } from "axios";
import { Poi } from "../../../types";
import { ICanCreate } from "./ICanCreate";
import { ICanDelete } from "./ICanDelete";
import { ICanUpdate } from "./ICanUpdate";
import { Repository } from "./Repository";
import { ICanRead } from "./ICanRead";

export class PoiRepository extends Repository implements ICanRead<Poi> {
  async get(restAuth: AxiosRequestConfig | undefined): Promise<Poi[]> {
    const data = await this.api.get(restAuth, "/wp/v2/poi");
    if (data) {
      const panos: Poi[] = data.map((item: any) => ({
        id: item.id,
        title: item.title.rendered,
      }));
      return panos;
    }

    return [];
  }

  async single(restAuth: AxiosRequestConfig | undefined, id: number) {
    return {} as Poi;
  }

  async create(restAuth: AxiosRequestConfig<any>, item: Poi) {
    return item;
  }

  async update(item: Poi) {
    return item;
  }

  async delete(id: number) {
    return false;
  }
}