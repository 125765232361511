import { Http } from "@status/codes";
import Axios, { AxiosRequestConfig } from "axios";

let origin = window.origin;

if (process.env.NODE_ENV === "development") {
  origin = process.env.REACT_APP_PROXY_HOST!;
}

export class WordPressApi {
  static instance: WordPressApi;
  baseUrl: string = origin;

  private constructor() {
  }

/**
 * The function getInstance() returns an instance of the WordPressApi class, creating a new instance if
 * one does not already exist.
 * @returns An instance of the WordPressApi class is being returned.
 */
  public static getInstance(): WordPressApi {
    if (!WordPressApi.instance) {
      WordPressApi.instance = new WordPressApi();
    }
    return WordPressApi.instance;
  }

  /**
 * Makes a request and returns the response data if the status is OK.
 * @param {AxiosRequestConfig | undefined} restAuth - Used to provide authentication information for making
 * HTTP requests.
 * @param {string} url - A string that represents the endpoint or route that you want to make a request to.
 * @returns If the request succeeded, the data from the response is returned. Otherwise, `null` is returned.
 */
  async get(restAuth: AxiosRequestConfig | undefined, url: string) {
    if (restAuth) {
      try {
        const response = await Axios.get(
          `${this.baseUrl}/wp-json${url}`,
          restAuth
        );
        if (response.status === Http.Ok) return response?.data;
      } catch (error) {}
    }
    return null;
  }

  /**
 * Makes a request and returns the response data if the status is OK.
 * @param {AxiosRequestConfig | undefined} restAuth - Used to provide authentication information for making
 * HTTP requests.
 * @param {string} url - A string that represents the endpoint or route that you want to make a request to.
 * @returns If the request succeeded, the data from the response is returned. Otherwise, `null` is returned.
 */
  async single(restAuth: AxiosRequestConfig | undefined, url: string) {
    if (restAuth) {
      try {
        const response = await Axios.get(
          `${this.baseUrl}/wp-json${url}`,
          restAuth
        );
        if (response.status === Http.Ok) return response?.data;
      } catch (error) {}
    }
    return null;
  }

/**
 * Sends a request to a specified URL with a given body and authentication configuration, returning the response data if the request is successful.
 * @param {AxiosRequestConfig | undefined} restAuth - Used to provide authentication information for making
 * HTTP requests.
 * @param {string} url - A string that represents the endpoint or route that you want to make a request to.
 * @param {Object} body - Contains the payload that you want to send.
 * @returns If the request succeeded, the data from the response is returned. Otherwise, `null` is returned.
 */
  async create(restAuth: AxiosRequestConfig | undefined, url: string, body: Object) {
    if (restAuth) {
      try {
        const response = await Axios.post(
          `${this.baseUrl}/wp-json${url}`,
          body,
          restAuth,
        );
        if (response.status === Http.Created) return response?.data;
      } catch (error) {}
    }
    return null;
  }

  async update(restAuth: AxiosRequestConfig | undefined, url: string, body: Object) {
    if (restAuth) {
      try {
        const response = await Axios.post(
          `${this.baseUrl}/wp-json${url}`,
          body,
          restAuth,
        );
        if (response.status === Http.Ok) return response?.data;
      } catch (error) {}
    }
    return null;
  }

  async delete(restAuth: AxiosRequestConfig | undefined, url: string) {
    if (restAuth) {
      try {
        const response = await Axios.delete(
          `${this.baseUrl}/wp-json${url}`,
          restAuth
        );
        if (response.status === Http.Ok) return response?.data;
      } catch (error) {}
    }
    return null;
  }

  async publish(restAuth: AxiosRequestConfig | undefined) {
    if (restAuth) {
      try {
        const response = await Axios.post(
          `${this.baseUrl}/wp-json/sep_edit/v1/changes/confirm`,
          null,
          restAuth
        );
        return response.status === Http.NoContent;
      } catch (error) {}
    }
    return true;
  }

  async discard(restAuth: AxiosRequestConfig | undefined) {
    if (restAuth) {
      try {
        const response = await Axios.post(
          `${this.baseUrl}/wp-json/sep_edit/v1/changes/revert`,
          null,
          restAuth
        );
        return response.status === Http.NoContent;
      } catch (error) {}
    }
    return true;
  }
}
