import { decodeEntities } from "@wordpress/html-entities";
import {
  LayerGroup,
  Pano,
  WordpressFaq,
  WordpressMapFaqData,
  WordpressPanoFaqData,
  MapFaqData,
  PanoFaqData,
  Faq,
  Scenario,
  Poi,
} from "../types";

function transformWordpressFaq(
  data: WordpressFaq<WordpressMapFaqData | WordpressPanoFaqData>,
  layerGroups: LayerGroup[],
  panos: Pano[],
  scenarios: Scenario[]
): Faq<MapFaqData | PanoFaqData> {
  const scenario = scenarios.find((s) => s.id === data.acf.scenario) || scenarios[0];

  const partial = {
    question: decodeEntities(data.acf.question),
    answer: decodeEntities(data.acf.answer),
    scenario,
    type: data.acf.location.type,
    id: data.id,
    slug: data.slug || `draft-${data.id}`,
    status: data.status,
  };

  let locationData: MapFaqData | PanoFaqData | null = null;

  if (isWordpressMapFaq(data)) {
    const latitude = Number(data.acf.location.map_pin.latitude);
    const longitude = Number(data.acf.location.map_pin.longitude);
    const layer = layerGroups.find((l) => l.id === data.acf.location.layer);

    locationData = {
      layerGroup: layer,
      location: {
        latitude,
        longitude,
      },
    } as MapFaqData;
  } else {
    let d = data as WordpressFaq<WordpressPanoFaqData>;
    const pitch = Number(d.acf.location.panorama_pin.pitch);
    const yaw = Number(d.acf.location.panorama_pin.yaw);
    const pano = panos.find((p) => p.id === d.acf.location.panorama);

    locationData = {
      pano,
      location: { pitch, yaw },
    } as PanoFaqData;
  }

  const rv: Faq<MapFaqData | PanoFaqData> = {
    ...partial,
    data: locationData,
  };

  return rv;
}

export function transformWordpressFaqs(
  data: WordpressFaq<WordpressMapFaqData | WordpressPanoFaqData>[],
  layerGroups: LayerGroup[],
  panos: Pano[],
  scenarios: Scenario[]
) {
  return data.map((f) => transformWordpressFaq(f, layerGroups, panos, scenarios));
}

export function isMapFaq(faqItem: Faq<MapFaqData | PanoFaqData>): faqItem is Faq<MapFaqData> {
  return faqItem.type === "map";
}

export function isPanoFaq(faqItem: Faq<MapFaqData | PanoFaqData>): faqItem is Faq<PanoFaqData> {
  return faqItem.type === "pano";
}

export const getFaqItems = (slug: string, faqItems: Faq<MapFaqData | PanoFaqData>[]) => {
  let faqs = faqItems.filter((f) => {
    if (isMapFaq(f)) {
      return f.data.layerGroup.slug === slug;
    } else if (isPanoFaq(f)) {
      return f.data.pano.slug === slug;
    }
    return false;
  });
  return faqs;
};

function isWordpressMapFaq(
  faqItem: WordpressFaq<WordpressMapFaqData | WordpressPanoFaqData>
): faqItem is WordpressFaq<WordpressMapFaqData> {
  return faqItem.acf.location.type === "map";
}

export const poiFromMapFaq = (faq: Faq<MapFaqData>): Poi => {
  return {
    id: faq.id,
    title: faq.question.replace("<p>", "<p>Vraag: "),
    content: faq.answer.replace("<p>", "<p>Antwoord: "),
    excerpt: faq.answer.replace("<p>", "<p>Antwoord: "),
    slug: faq.slug || `draft-${faq.id}`,
    type: "faq",
    featuredMedia: 0,
    latitude: faq.data.location.latitude,
    longitude: faq.data.location.longitude,
    status: faq.status,
    showBadge: false,
    scenarios: [],
  };
};
