import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useAppState } from "../../AppContext";
import { useAuthenticatedState } from "../../hooks/useAuthenticatedState";
import { Capability, useCapability } from "../../hooks/useCapability";
import { EditorData, EditorDataMap } from "../../utils/editorUtils";
import { PanoRepository } from "./Repositories/PanoRepository";
import { PanoLinkRepository } from "./Repositories/PanoLinkRepository";
import { useRestAuth } from "../../hooks/useRestAuth";
import { AxiosRequestConfig } from "axios";
import { MapRepository } from "./Repositories/MapRepository";
import { PanoPoiRepository } from "./Repositories/PanoPoiRepository";
import { PoiRepository } from "./Repositories/PoiRepository";
import { MapPanoRepository } from "./Repositories/MapPanoRepository";
import { MapPoiRepository } from "./Repositories/MapPoiRepository";

type SetContextValue<K extends keyof IEditContext> = React.Dispatch<
  React.SetStateAction<IEditContext[K]>
>;

interface IEditContext {
  target?: number;
  setTarget: SetContextValue<"target">;
  data: EditorData;
  editData: <T extends keyof EditorDataMap>(newData: Partial<EditorData<T>>) => void;
  restAuth: AxiosRequestConfig | undefined;
  panoRepository: PanoRepository;
  panoLinkRepository: PanoLinkRepository;
  mapRepository: MapRepository;
  panoPoiRepository: PanoPoiRepository;
  poiRepository: PoiRepository;
  mapPanoRepository: MapPanoRepository;
  mapPoiRepository: MapPoiRepository;
}

const initialState: IEditContext = Object.freeze({
  setTarget: () => {},
  data: {} as EditorData,
  editData: () => {},
  restAuth: {} as AxiosRequestConfig,
  panoRepository: {} as PanoRepository,
  panoLinkRepository: {} as PanoLinkRepository,
  mapRepository: {} as MapRepository,
  panoPoiRepository: {} as PanoPoiRepository,
  poiRepository: {} as PoiRepository,
  mapPanoRepository: {} as MapPanoRepository,
  mapPoiRepository: {} as MapPoiRepository,
});
const EditContext = createContext<IEditContext>(initialState);

export const EditStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const canEditSep = useCapability(Capability.EditSep);
  const [target, setTarget] = useAuthenticatedState<IEditContext["target"]>(undefined, Capability.EditSep);
  const [data, setData] = useAuthenticatedState<IEditContext["data"]>({} as EditorData, Capability.EditSep);
  const { setShowDraft } = useAppState();
  const locationQuery = window.location.search;
  const restAuth = useRestAuth();

  const panoRepository = useMemo(() => new PanoRepository(), []);
  const panoLinkRepository = useMemo(() => new PanoLinkRepository(), []);
  const mapRepository = useMemo(() => new MapRepository(), []);
  const panoPoiRepository = useMemo(() => new PanoPoiRepository(), []);
  const poiRepository = useMemo(() => new PoiRepository(), []);
  const mapPanoRepository = useMemo(() => new MapPanoRepository(), []);
  const mapPoiRepository = useMemo(() => new MapPoiRepository(), []);
  const previewMode = useMemo(() => {
    const search = new URLSearchParams(locationQuery);
    return search.has("preview");
  }, [locationQuery]);

  useEffect(() => {
    setShowDraft(true);
  }, [previewMode, setShowDraft]);

  const editData = useCallback(
    <T extends keyof EditorDataMap>(newData: Partial<EditorData<T>>) => {
      setData((prevData) => {
        const updatedData = { ...prevData } as EditorData<T>;
        for (const key in newData) {
          if (newData.hasOwnProperty(key)) {
            updatedData[key] = newData[key] as any;
          }
        }
        return updatedData;
      });
    },
    [setData]
  );

  return (
    <EditContext.Provider
      value={
        canEditSep
          ? {
              target,
              setTarget,
              data,
              editData,
              restAuth,
              panoRepository,
              panoLinkRepository,
              mapRepository,
              panoPoiRepository,
              poiRepository,
              mapPanoRepository,
              mapPoiRepository
            }
          : initialState
      }
    >
      {children}
    </EditContext.Provider>
  );
};

export const useEditState = () => useContext(EditContext);
