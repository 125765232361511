import { useEffect, useState } from "react";
import {
  MainSidebar,
  OverviewPanel,
  PageTitle,
  MainContent,
  SideBarTopActionButton,
  PageTitleWrapper,
} from "../EditorStyles";
import PanoMetaDataEditor from "./PanoMetaDataEditor";
import { useEditState } from "../EditContext";
import PanoEditor from "./PanoEditor";
import { ReactComponent as ArrowLeftIcon } from "./../../../images/editor-icons/arrow-left.svg";
import { Pano } from "../../../types";
import SpinnerWrapper from "../BaseComponents/SpinnerWrapper";
import { useRestAuth } from "../../../hooks/useRestAuth";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAppState } from "../../../AppContext";

const SinglePanoPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [pano, setPano] = useState<Pano>();
  const { target, setTarget, panoRepository } = useEditState();
  const restAuth = useRestAuth();
  const history = useHistory();
  const { state } = useAppState();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      setTarget(Number(id));
    }
  }, [id, setTarget]);
  
  /* Fetch metadata for panorama. */
  useEffect(() => {
    const fetchData = async () => {
      if (target && restAuth) {
        setLoading(true);
        const data = state.panos.find((pano) => pano.id === target);
        setPano(data);
        setLoading(false);
      }
    };

    fetchData();
  }, [panoRepository, restAuth, state.panos, target]);

  return (
    <>
      <MainSidebar>
        <OverviewPanel>
          <SideBarTopActionButton onClick={() => history.push("/editor/pano")}>
            <ArrowLeftIcon />
          </SideBarTopActionButton>
          <SpinnerWrapper loading={loading}>
            <PageTitleWrapper>
              <PageTitle>{pano?.title}</PageTitle>
            </PageTitleWrapper>
            <PanoMetaDataEditor pano={pano} />
          </SpinnerWrapper>
        </OverviewPanel>
      </MainSidebar>

      <MainContent>
        <SpinnerWrapper loading={loading}>
          {pano && <PanoEditor pano={pano} />}
        </SpinnerWrapper>
      </MainContent>
    </>
  );
};

export default SinglePanoPage;
