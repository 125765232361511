import { useRef, useState } from "react";
import { TooltipTip, TooltipWrapper } from "../EditorStyles";

const Tooltip: React.FC<{
  children: React.ReactNode;
  message: React.ReactNode;
  disabled?: boolean;
}> = ({ children, message, disabled = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const showTooltip = () => {
    if (!disabled) {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(true);
      }, 300);
    }
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsVisible(false);
  };

  if (disabled) return <>{children}</>;

  return (
    <TooltipWrapper
      role="button"
      tabIndex={0}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          setIsVisible(!isVisible);
        }
      }}
    >
      {children}
      {isVisible && (
        <TooltipTip>
          {message}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;