import { Button, LinkAdderPlaceholder } from "../../EditorStyles";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ClickIcon } from "./../../../../images/editor-icons/mouse-pointer-click.svg";
import { Picking } from "../../../../utils/editorUtils";

interface MapPickerPlaceholderProps {
  setPicking: React.Dispatch<React.SetStateAction<Picking>>;
}

const MapPickerPlaceholder: React.FC<MapPickerPlaceholderProps> = ({ setPicking }) => {
    return (
        <>
        <LinkAdderPlaceholder>
          <h2>
            <ClickIcon style={{marginRight: "6px", position: "relative", top: "3px"}}/>
            <FormattedMessage
              id="editor.maps.link.picking_placeholder_heading"
              defaultMessage="Select a location"
              description="Placeholder text heading when creating a new link on a map"
            />
          </h2>
          <p>
            <FormattedMessage
              id="editor.maps.link.picking_placeholder_text"
              defaultMessage="Click on your desired location on the map to add a new link."
              description="Placeholder text when creating a new link on a map"
            />
          </p>
          <Button onClick={() => setPicking(Picking.Off)}>
            <FormattedMessage
              id="editor.maps.link.cancel"
              defaultMessage="Stop selecting"
              description="Text for exit picking mode button on ap map"
            />
          </Button>
        </LinkAdderPlaceholder>
      </>
    );
};

export default MapPickerPlaceholder;
