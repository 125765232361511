import { useEffect, useState } from "react";
import {
  MainSidebar,
  OverviewPanel,
  PageTitle,
  SidebarListItem,
  MainContent,
  SidebarList,
  IconButton,
  PageTitleWrapper,
} from "../EditorStyles";
import { FormattedMessage } from "react-intl";
import { LayerGroup } from "../../../types";
import SpinnerWrapper from "../BaseComponents/SpinnerWrapper";
import NoDataNotice from "../BaseComponents/NoDataNotice";
import { useEditState } from "../EditContext";
import SingleMapPage from "./SingleMapPage";
import MapEditor from "./MapEditor";
import { useRestAuth } from "../../../hooks/useRestAuth";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as HelpIcon } from "./../../../images/editor-icons/circle-help.svg";
import InlinePopup from "../BaseComponents/InlinePopup";
import { useAppState } from "../../../AppContext";

const MapPage = () => {
  const [maps, setMaps] = useState<LayerGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setTarget, target, mapRepository } = useEditState();
  const restAuth = useRestAuth();
  const history = useHistory();
  const location = useLocation();
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const { state } = useAppState();

  useEffect(() => {
    if (location.pathname === "/editor/map" && target) {
      setTarget(undefined);
    }
  }, [location.pathname, setTarget, target]);

  useEffect(() => {
    const fetchData = async () => {
      if (restAuth) {
        setLoading(true);
        const data = state.map.layerGroups;
        setMaps(data);
        setLoading(false);
      }
    };

    fetchData();
  }, [mapRepository, restAuth, state.map.layerGroups]);

  return (
    <Switch>
      <Route exact path="/editor/map">
        <MainSidebar>
          <OverviewPanel>
            <PageTitleWrapper>
              <PageTitle>
                <FormattedMessage
                  id="editor.maps.title"
                  defaultMessage="Map layers"
                  description="Title text for map layer overview"
                />
              </PageTitle>
              <div style={{ position: "relative" }}>
                <IconButton onClick={() => setShowHelpPopup(true)}>
                  <HelpIcon />
                </IconButton>
                {showHelpPopup && (
                  <InlinePopup onClose={() => setShowHelpPopup(false)} >
                    <h3>
                      <FormattedMessage
                        id="editor.maps.help.title"
                        defaultMessage="Manual: managing the map"
                        description="Title for map help popup"
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id="editor.maps.help.p1"
                        defaultMessage="The general map contains all links to panoramic photos and POI placed as points on the map."
                        description="Paragraph 1 for map help popup"
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="editor.maps.help.p2"
                        defaultMessage="These points can be edited and deleted. Points located on the general map are not visible by default on maps in the Stakeholder Engagement Platform or the Online Visitor Centre."
                        description="Paragraph 2 for map help popup"
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="editor.maps.help.p3"
                        defaultMessage="A specific map layer can be managed by clicking on the map layer in the overview."
                        description="Paragraph 3 for map help popup"
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="editor.maps.help.p4"
                        defaultMessage="By clicking on a point on the detail page of a map layer, that point can be turned on or off for that map layer."
                        description="Paragraph 4 for map help popup"
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="editor.maps.help.p5"
                        defaultMessage="Any point that is turned on on a map layer will be shown on that map layer in the Stakeholder Engagement Platform or the Online Visitor Centre."
                        description="Paragraph 5 for map help popup"
                      />
                    </p>
                  </InlinePopup>
                )}
              </div>
            </PageTitleWrapper>
            <SpinnerWrapper loading={loading}>
              {maps.length === 0 ? (
                <NoDataNotice />
              ) : (
                <SidebarList>
                  {maps.map((map: LayerGroup) => (
                    <SidebarListItem
                      key={map.id}
                      onClick={() => history.push(`/editor/map/${map.id}/${map.scenarios[0].slug}`)}
                    >
                      {map.title}
                    </SidebarListItem>
                  ))}
                </SidebarList>
              )}
            </SpinnerWrapper>
          </OverviewPanel>
        </MainSidebar>
        <MainContent>
          <MapEditor />
        </MainContent>
      </Route>
      <Route path="/editor/map/:id/:scenarioSlug" component={SingleMapPage} />
    </Switch>
  );
};

export default MapPage;
