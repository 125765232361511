import "mapbox-gl/dist/mapbox-gl.css";
import BaseStyles from "./BaseStyles";

import React, { useEffect } from "react";
import { StateProvider } from "./AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import AppContent from "./AppContent";
import { InterfaceStateProvider } from "./InterfaceContext";
import { EditStateProvider } from "./components/Editor/EditContext";
import { SurveyStateProvider } from "./SurveyContext";
import { DebugContextProvider } from "./DebugContext";
import { localOrigins } from "./utils/urlUtils";
import { renderToStaticMarkup } from "react-dom/server";
import { ReactComponent as ExtIcon } from "./images/link-external.svg";

function App() {
  useEffect(() => {
    const extLinks = `a${localOrigins.map((o) => `:not([href^="${o}"])`).join("")}:not([download])`;

    const replaceIconHackIntervalId = setInterval(
      () =>
        document.querySelectorAll(`${extLinks}:not(.ext-icon-added)`).forEach((el) => {
          if (!el.classList.contains("ext-icon-added") || el.childElementCount === 0) {
            el.insertAdjacentHTML("beforeend", externalIconHtml);
          }
          el.classList.add("ext-icon-added");
        }),
      Math.ceil(1000 / 60)
    );

    return () => {
      clearInterval(replaceIconHackIntervalId);
      document.querySelectorAll(".ext-icon-added").forEach((el) => {
        el.classList.remove("ext-icon-added");
        el.removeChild(el.lastChild!);
      });
    };
  }, []);
  // TODO: figure out how to combine this with survey
  // const [tabUser, setTabUser] = useState(false);

  // useEffect(() => {
  // window.addEventListener("keydown", (e) => {
  //   if (e.key === "Tab") {
  //     setTabUser(true);
  //   }
  // });
  // window.addEventListener("mousedown", () => setTabUser(false));
  // }, []);

  return (
    <>
      <Router>
        <DebugContextProvider>
          <StateProvider>
            <BaseStyles />
            <SurveyStateProvider>
              <InterfaceStateProvider>
                <EditStateProvider>
                  <AppContent />
                </EditStateProvider>
              </InterfaceStateProvider>
            </SurveyStateProvider>
          </StateProvider>
        </DebugContextProvider>
      </Router>
    </>
  );
}

export default App;

const externalIconHtml = renderToStaticMarkup(
  <ExtIcon
    className="ext-icon"
    style={{
      width: "1rem",
      height: "1rem",
      marginInline: "0.25rem",
      display: "inline-block",
      verticalAlign: "middle",
    }}
  />
);

