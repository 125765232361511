import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Map as ReactMapGL, MapRef } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { useAppState } from "../../../AppContext";
import { MapContainer } from "../../Map/MapStyles";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  BasicEditorMapPano,
  BasicEditorMapPoi,
  EditorData,
  EditorMapPano,
  EditorMapPoi,
  Picking,
  SelectedLink,
} from "../../../utils/editorUtils";
import { useEditState } from "../EditContext";
import { useRestAuth } from "../../../hooks/useRestAuth";
import SpinnerWrapper from "../BaseComponents/SpinnerWrapper";
import { MemoizedEditorPoiMarker } from "./EditorPoiMarker";
import { MemoizedEditorPanoMarker } from "./EditorPanoMarker";
import MapLinkEditor from "./Link/MapLinkEditor";
import { LinkEditorDialog } from "../EditorStyles";
import MapPickerPlaceholder from "./Link/MapPickerPlaceholder";
import MapAddButtons from "./MapAddButtons";
import { useMapState } from "../../../hooks/useMapState";
import { calculateViewport } from "../../../utils/mapUtils";
import WebMercatorViewport from "viewport-mercator-project";
import { LayerGroup, LayerGroupScenario } from "../../../types";
import { EditorScenarioSwitch } from "../BaseComponents/EditorScenarioSwitch";
import { useParams } from "react-router-dom";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const customAttribution =
  '<a href="https://www.theimagineers.com/" target="_blank">© The Imagineers</a>';

interface MapEditorProps {
  layer?: LayerGroup;
}

const MapEditor: React.FC<MapEditorProps> = ({ layer }) => {
  const { state } = useAppState();
  const mapRef = useRef<MapRef>(null);
  const restAuth = useRestAuth();
  const mapState = useMapState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { mapPanoRepository, mapPoiRepository, data, editData, target } = useEditState();
  const [editorData, setEditorData] = useState<EditorData<"map">>(data as EditorData<"map">);
  const [selectedLink, setSelectedLink] = useState<SelectedLink | undefined>(undefined);
  const [picking, setPicking] = useState(Picking.Off);
  const [linkEditorLoading, setLinkEditorLoading] = useState(false);
  const { scenarioSlug } = useParams<{ scenarioSlug: string }>();
  const [linkEditorCoordinatesLoading, setLinkEditorCoordinatesLoading] = useState(false);

  useEffect(() => {
    setEditorData(data as EditorData<"map">);
  }, [data]);

  const scenario = useMemo(() => {
    if (scenarioSlug && layer) {
      return layer.scenarios.find(s => s.slug === scenarioSlug);
    }
  }, [layer, scenarioSlug]);

  useEffect(() => {
    async function fetchLinks() {
      if (restAuth) {
        const panoLinks = await mapPanoRepository.get(restAuth, target);
        const poiLinks = await mapPoiRepository.get(restAuth, target);
        let unlinkedPanoLinks = [] as EditorMapPano[];
        let unlinkedPoiLinks = [] as EditorMapPoi[];
        let unusedPanoLinks = [] as BasicEditorMapPano[];
        let unusedPoiLinks = [] as BasicEditorMapPoi[];
        if (target) {
          unlinkedPanoLinks = await mapPanoRepository.get_unlinked(restAuth, target);
          unlinkedPoiLinks = await mapPoiRepository.get_unlinked(restAuth, target);
        } else {
          unusedPanoLinks = await mapPanoRepository.get_unused(restAuth);
          unusedPoiLinks = await mapPoiRepository.get_unused(restAuth);
        }
        if (panoLinks || poiLinks || unlinkedPanoLinks || unlinkedPoiLinks) {
          editData<"map">({
            panorama_location: panoLinks,
            poi_location: poiLinks,
            unlinked_panorama_location: unlinkedPanoLinks,
            unlinked_poi_location: unlinkedPoiLinks,
            unused_panorama_location: unusedPanoLinks,
            unused_poi_location: unusedPoiLinks,
          });
        }
        setDataLoaded(true);
      }
    }
    fetchLinks();
  }, [editData, mapPanoRepository, mapPoiRepository, restAuth, target]);

  const [viewport, setViewport] = useState<any>(() => {
    const { innerWidth, innerHeight } = window;
    // Return if we have a state from the url
    if (mapState) {
      return new WebMercatorViewport({
        latitude: mapState.lat,
        longitude: mapState.long,
        zoom: mapState.zoom,
        width: innerWidth,
        height: innerHeight,
      });
    } else {
      return calculateViewport(state.map.initialBounds);
    }
  });

  const addLink = useCallback(
    async (lat: number, long: number) => {
      const pickingCopy = picking;
      setPicking(Picking.Off);
      setLinkEditorLoading(true);
      if (pickingCopy === Picking.Pano && restAuth) {
        const links = await mapPanoRepository.create(restAuth, { long, lat });
        const previous_unused_links = editorData.unused_panorama_location;
        const unused_links = await mapPanoRepository.get_unused(restAuth);
        editData<"map">({ panorama_location: links, unused_panorama_location: unused_links });
        const newLink = previous_unused_links.find(
          (link) => !unused_links.some((unusedLink) => unusedLink.pano === link.pano)
        );
        if (newLink) {
          setSelectedLink({ type: "pano", item: newLink.pano });
        }
      }
      if (pickingCopy === Picking.Poi && restAuth) {
        const links = await mapPoiRepository.create(restAuth, { lat, long });
        const previous_unused_links = editorData.unused_poi_location;
        const unused_links = await mapPoiRepository.get_unused(restAuth);
        editData<"map">({ poi_location: links, unused_poi_location: unused_links });
        const newLink = previous_unused_links.find(
          (link) => !unused_links.some((unusedLink) => unusedLink.poi === link.poi)
        );
        if (newLink) {
          setSelectedLink({ type: "poi", item: newLink.poi });
        }
      }
      setLinkEditorLoading(false);
    },
    [editData, editorData.unused_panorama_location, editorData.unused_poi_location, mapPanoRepository, mapPoiRepository, picking, restAuth]
  );

  return (
    <SpinnerWrapper loading={!dataLoaded || !state.map || !state.map.accessToken}>
      <MapContainer style={{ width: "100%", height: "100vh" }}>
        <ReactMapGL
          {...viewport}
          key={state.map.accessToken}
          ref={mapRef}
          customAttribution={customAttribution}
          mapboxAccessToken={state.map.accessToken}
          mapStyle={state.map.style}
          minZoom={state.mapConfig.minZoom}
          maxZoom={state.mapConfig.maxZoom}
          onMove={(e) => setViewport(e.viewState)}
          onClick={async (event) => {
            if (picking !== Picking.Off) {
              addLink(event.lngLat.lat, event.lngLat.lng);
            }
          }}
        >
          {!target ? (
            <LinkEditorDialog>
              <SpinnerWrapper loading={linkEditorLoading}>
              {picking !== Picking.Off && <MapPickerPlaceholder setPicking={setPicking} />}
              {picking === Picking.Off && (
                <>
                {selectedLink ? (
                  <MapLinkEditor
                  selectedLink={selectedLink}
                  onCloseEditor={() => setSelectedLink(undefined)}
                  setSelectedLink={setSelectedLink}
                  coordinateInputsLoading={linkEditorCoordinatesLoading}
                  />
                ) : (
                  <MapAddButtons setPicking={setPicking} />
                )}
                </>
              )}
              </SpinnerWrapper>
            </LinkEditorDialog>
          ) : layer && scenario ? (
            <EditorScenarioSwitch
              item={layer}
              selectedScenario={scenario}
            />
          ) : null}
          {editorData.panorama_location?.map((pano: any, index: number) => {
            const currentPano = state.panos.find((p) => p.id === pano.pano);
            if (!scenario || currentPano?.scenarios.some((s) => s.slug === scenario.slug)) {
              return (
                <MemoizedEditorPanoMarker
                  key={index}
                  index={index}
                  point={pano}
                  selectedLink={selectedLink}
                  setSelectedLink={setSelectedLink}
                  setLinkEditorCoordinatesLoading={setLinkEditorCoordinatesLoading}
                  setLinkEditorLoading={setLinkEditorLoading}
                />
              );
            }
            return null;
          })}
          {editorData.poi_location?.map((poi: any, index: number) => {
            const currentPoi = state.pois.find((p) => p.id === poi.poi)
            if (!scenario || currentPoi?.scenarios.some((s) => s === scenario.id)) {
              return (
                <MemoizedEditorPoiMarker
                  key={index}
                  index={index}
                  point={poi}
                  selectedLink={selectedLink}
                  setSelectedLink={setSelectedLink}
                  setLinkEditorCoordinatesLoading={setLinkEditorCoordinatesLoading}
                  setLinkEditorLoading={setLinkEditorLoading}
                />
              );
            }
            return null;
          })}
          {editorData.unlinked_panorama_location?.map((pano: any, index: number) => {
            const currentPano = state.panos.find((p) => p.id === pano.pano);
            if (!scenario || currentPano?.scenarios.some((s) => s.slug === scenario.slug)) {
              return (
                <MemoizedEditorPanoMarker
                  key={index}
                  index={index}
                  point={pano}
                  selectedLink={selectedLink}
                  setSelectedLink={setSelectedLink}
                  setLinkEditorCoordinatesLoading={setLinkEditorCoordinatesLoading}
                  setLinkEditorLoading={setLinkEditorLoading}
                  unlinked={true}
                />
              );
            }
            return null;
          })}
          {editorData.unlinked_poi_location?.map((poi: any, index: number) => {
            const currentPoi = state.pois.find((p) => p.id === poi.poi)
            if (!scenario || currentPoi?.scenarios.some((s) => s === scenario.id)) {
              return (
                <MemoizedEditorPoiMarker
                  key={index}
                  index={index}
                  point={poi}
                  selectedLink={selectedLink}
                  setSelectedLink={setSelectedLink}
                  setLinkEditorCoordinatesLoading={setLinkEditorCoordinatesLoading}
                  setLinkEditorLoading={setLinkEditorLoading}
                  unlinked={true}
                />
              );
            }
            return null;
          })}
        </ReactMapGL>
      </MapContainer>
    </SpinnerWrapper>
  );
};

export default MapEditor;
