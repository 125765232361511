import React, { useCallback, useMemo } from "react";
import { Marker as MapMarker } from "react-map-gl";
import { MarkerIconStyles } from "../../Map/MapStyles";
import { markerSVGSwitch } from "../../../shared/components/MarkerImages";
import classNames from "classnames";
import { useEditState } from "../EditContext";
import { EditorData, EditorMapPoi, SelectedLink } from "../../../utils/editorUtils";
import { MapPoi, Poi } from "../../../types";
import { useRestAuth } from "../../../hooks/useRestAuth";
import { MapPointLabel } from "./../EditorStyles";

interface EditorPoiMarkerProps {
  index: number;
  point: EditorMapPoi;
  selectedLink: SelectedLink | undefined;
  setSelectedLink: React.Dispatch<React.SetStateAction<SelectedLink | undefined>>;
  setLinkEditorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkEditorCoordinatesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  unlinked?: boolean;
}

const EditorPoiMarker: React.FC<EditorPoiMarkerProps> = ({
  index,
  point,
  selectedLink,
  setSelectedLink,
  setLinkEditorLoading,
  setLinkEditorCoordinatesLoading,
  unlinked,
}) => {
  const { poiRepository, mapPoiRepository, target, editData, data } = useEditState();
  const restAuth = useRestAuth();
  const [poi, setPoi] = React.useState<Poi | null>(null);
  const disabled = unlinked || (selectedLink && (selectedLink.type !== 'poi' || selectedLink.item !== point.poi));

  React.useEffect(() => {
    const fetchPoi = async () => {
      if (restAuth) {
        const fetchedPoi = await poiRepository.single(restAuth, point.poi);
        setPoi(fetchedPoi);
      }
    };
    fetchPoi();
  }, [poiRepository, point, restAuth]);

  const offset = useMemo(() => {
    if (poi) {
      switch (poi.type) {
        case "pano":
        case "video":
        case "vcr":
          return { offsetLeft: -18, offsetTop: -18 };
        case "faq":
          return { offsetLeft: -22, offsetTop: -37 };
        default:
          return { offsetLeft: -15, offsetTop: -37 };
      }
    }
  }, [poi]);

  const handleDragEnd = useCallback(
    async (event) => {
      if (restAuth) {
        if (selectedLink?.item !== index) {
          setLinkEditorLoading(true);
          setSelectedLink({ type: "poi", item: point.poi });
        } else {
          setLinkEditorCoordinatesLoading(true);
        }
        const lat = event.lngLat.lat;
        const long = event.lngLat.lng;  
        const links = await mapPoiRepository.update(restAuth, { lat, long }, point.poi);
        editData<"map">({ poi_location: links });
        if (selectedLink?.item !== index) {
          setLinkEditorLoading(false);
        } else {
          setLinkEditorCoordinatesLoading(false);
        }
      }
    },
    [editData, index, mapPoiRepository, point.poi, restAuth, selectedLink?.item, setLinkEditorCoordinatesLoading, setLinkEditorLoading, setSelectedLink]
  );

  if (!offset || !poi) return null;
  return (
    <MapMarker
      latitude={point.lat}
      longitude={point.long}
      offset={[offset.offsetLeft, offset.offsetTop]}
      anchor="top-left"
      draggable={target === undefined}
      onDragEnd={handleDragEnd}
      onClick={async (event) => {
        if (!target) {
          setSelectedLink({ type: "poi", item: point.poi });
        } else {
          if (restAuth) {
            if (unlinked) {
              const unlinked_links = (data as EditorData<"map">).unlinked_poi_location.filter(
                (_, i) => i !== index
              );
              const links = [...(data as EditorData<"map">).poi_location, point];
              editData<"map">({ poi_location: links, unlinked_poi_location: unlinked_links });
              await mapPoiRepository.create(restAuth, { poi: point.poi }, target);
            } else {
              const links = (data as EditorData<"map">).poi_location.filter((_, i) => i !== index);
              const unlinked_links = [...(data as EditorData<"map">).unlinked_poi_location, point];
              editData<"map">({ poi_location: links, unlinked_poi_location: unlinked_links });
              await mapPoiRepository.delete(restAuth, point.poi, target);
            }
          }
        }
      }}
    >
      <div style={{ display: "flex" }}>
        <MarkerIconStyles
          className={classNames({
            question: poi.type === "faq",
          })}
        >
          {markerSVGSwitch(
            { type: poi.type } as MapPoi,
            false,
            disabled ? "--color-neutral-50" : point.color,
            disabled ? 0.4 : 1
          )}
        </MarkerIconStyles>
        <MapPointLabel $disabled={disabled}>{point.title}</MapPointLabel>
      </div>
    </MapMarker>
  );
};

export const MemoizedEditorPoiMarker = React.memo(EditorPoiMarker);
