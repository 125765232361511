import { AxiosRequestConfig } from "axios";
import { Repository } from "./Repository";
import { BasicEditorMapPoi, EditorMapPoi, formatMapPois } from "../../../utils/editorUtils";

export class MapPoiRepository extends Repository {
  async get(restAuth: AxiosRequestConfig | undefined, map?: number): Promise<EditorMapPoi[]> {
    const data = await this.api.get(
      restAuth,
      map ? `/sep_edit/v1/map/${map}/map_poi?context=edit` : "/sep_edit/v1/map/map_poi?context=edit",
    );

    if (data) {
      return formatMapPois(data);
    }
    return [];
  }

  async get_unlinked(restAuth: AxiosRequestConfig | undefined, map: number): Promise<EditorMapPoi[]> {
    const data = await this.api.get(
      restAuth,
      `/sep_edit/v1/map/${map}/map_poi/unlinked?context=edit`,
    );

    if (data) {
      return formatMapPois(data);
    }
    return [];
  }

  async get_unused(restAuth: AxiosRequestConfig | undefined): Promise<BasicEditorMapPoi[]> {
    const data = await this.api.get(
      restAuth,
      "/sep_edit/v1/map/map_poi/unlinked?context=edit",
    );

    if (data) {
      return data;
    }
    return [];
  }

  async create(restAuth: AxiosRequestConfig | undefined, item: {lat?: number, long?: number, color?: string} | {poi: number}, map?: number): Promise<EditorMapPoi[]> {
    const data = await this.api.create(
      restAuth,
      map ? `/sep_edit/v1/map/${map}/map_poi` : "/sep_edit/v1/map/map_poi",
      item,
    );

    if (data) {
      return formatMapPois(data);
    }
    return [];
  }

  async update(restAuth: AxiosRequestConfig | undefined, item: {lat?: number, long?: number, color?: string, poi? : number}, poi: number): Promise<EditorMapPoi[]> {
    const data = await this.api.update(
      restAuth,
      `/sep_edit/v1/map/map_poi/${poi}`,
      item,
    );
    if (data) {
      return formatMapPois(data);
    }
    return [];
  }

  async delete(restAuth: AxiosRequestConfig | undefined, poi: number, map?: number): Promise<EditorMapPoi[]> {
    const data = await this.api.delete(
      restAuth,
      map ? `/sep_edit/v1/map/${map}/map_poi/${poi}` : `/sep_edit/v1/map/map_poi/${poi}`,
    );

    if (data) {
      return formatMapPois(data);
    }
    return [];
  }
}