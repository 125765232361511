import React, { useEffect, useState } from "react";
import { EditorData, Picking } from "../../../utils/editorUtils";
import { FormattedMessage } from "react-intl";
import { StackedItems, Button } from "../EditorStyles";
import { ReactComponent as PanoramaIcon } from "./../../../images/editor-icons/camera.svg";
import { ReactComponent as PoiIcon } from "./../../../images/editor-icons/map-pin.svg";
import { useEditState } from "../EditContext";
import Tooltip from "../BaseComponents/Tooltip";

interface MapAddButtonsProps {
  setPicking: React.Dispatch<React.SetStateAction<Picking>>;
}

const MapAddButtons: React.FC<MapAddButtonsProps> = ({ setPicking }) => {
  const { data } = useEditState();
  const [editorData, setEditorData] = useState<EditorData<"map">>(data as EditorData<"map">);
  useEffect(() => {
    setEditorData(data as EditorData<"map">);
  }, [data]);

  const panoDisabled = !(
    editorData.unused_panorama_location && editorData.unused_panorama_location.length > 0
  );
  const poiDisabled = !(
    editorData.unused_poi_location && editorData.unused_poi_location.length > 0
  );

  return (
    <>
      <h2>
        <FormattedMessage
          id="editor.map.link.add_link"
          defaultMessage="Add new link to"
          description="Text for add link header"
        />
      </h2>
      <StackedItems>
        <Tooltip
          message={
            <FormattedMessage
              id="editor.map.link.add_link.no_pano_locations"
              defaultMessage="All available panoramas have already been placed."
              description="Tooltip for disabled panorama button when no panoramas are available"
            />
          }
          disabled={!panoDisabled}
        >
          <Button
            className={`primary ${panoDisabled ? "disabled" : ""}`}
            onClick={() => setPicking(Picking.Pano)}
            disabled={panoDisabled}
          >
            <PanoramaIcon />
            <FormattedMessage
              id="editor.map.link.add_pano"
              defaultMessage="Panorama"
              description="Text for add panorama link button"
            />
          </Button>
        </Tooltip>

        <Tooltip
          message={
            <FormattedMessage
              id="editor.map.link.add_link.no_poi_locations"
              defaultMessage="All available pois have already been placed."
              description="Tooltip for disabled POI button when no pois are available"
            />
          }
          disabled={!poiDisabled}
        >
          <Button
            className={`primary ${poiDisabled ? "disabled" : ""}`}
            onClick={() => setPicking(Picking.Poi)}
            disabled={poiDisabled}
          >
            <PoiIcon />
            <FormattedMessage
              id="editor.map.link.add_poi"
              defaultMessage="POI"
              description="Text for add poi link button"
            />
          </Button>
        </Tooltip>
      </StackedItems>
    </>
  );
};

export default MapAddButtons;
