import { AxiosRequestConfig } from "axios";
import { ICanRead } from "./ICanRead";
import { ICanUpdate } from "./ICanUpdate";
import { Repository } from "./Repository";
import { ICanCreate } from "./ICanCreate";
import { ICanDelete } from "./ICanDelete";
import { EditorPanoPoi, formatPanoPoi, formatPanoPois } from "../../../utils/editorUtils";

export class PanoPoiRepository extends Repository {
  async get(restAuth: AxiosRequestConfig | undefined, pano: number): Promise<EditorPanoPoi[]> {
    const data = await this.api.get(
      restAuth,
      `/sep_edit/v1/pano/${pano}/pano_poi?context=edit`,
    );

    if (data) {
      return formatPanoPois(data);
    }
    return [];
  }

  async single(): Promise<EditorPanoPoi> {
    return {} as EditorPanoPoi;
  }

  async create(restAuth: AxiosRequestConfig | undefined, item: EditorPanoPoi, pano: number): Promise<EditorPanoPoi[]> {
    const data = await this.api.create(
      restAuth,
      `/sep_edit/v1/pano/${pano}/pano_poi`,
      item,
    );

    if (data) {
      return formatPanoPois(data);
    }
    return [];
  }

  async update(restAuth: AxiosRequestConfig | undefined, item: Object, index: number, target: number): Promise<EditorPanoPoi[]> {
    const data = await this.api.update(
      restAuth,
      `/sep_edit/v1/pano/${target}/pano_poi/${index}`,
      item,
    );
    if (data) {
      return formatPanoPois(data);
    }
    return [];
  }

  async delete(restAuth: AxiosRequestConfig | undefined, index: number, pano: number): Promise<EditorPanoPoi[]> {
    const data = await this.api.delete(
      restAuth,
      `/sep_edit/v1/pano/${pano}/pano_poi/${index}`,
    );

    if (data) {
      return formatPanoPois(data);
    }
    return [];
  }
}