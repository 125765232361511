import { useEffect, useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import {
  MainSidebar,
  OverviewPanel,
  PageTitle,
  SidebarListItem,
  MainContent,
  SidebarList,
  PageTitleWrapper,
} from "../EditorStyles";
import { FormattedMessage } from "react-intl";
import MainContentPlaceholder from "../BaseComponents/MainContentPlaceholder";
import { Pano } from "../../../types";
import SpinnerWrapper from "../BaseComponents/SpinnerWrapper";
import NoDataNotice from "../BaseComponents/NoDataNotice";
import { useEditState } from "../EditContext";
import SinglePanoPage from "./SinglePanoPage";
import { useRestAuth } from "../../../hooks/useRestAuth";
import { useAppState } from "../../../AppContext";

const PanoPage = () => {
  const { target, setTarget } = useEditState();
  const { state } = useAppState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/editor/pano" && target) {
      setTarget(undefined);
    }
  }, [location.pathname, setTarget, target]);

  return (
    <Switch>
      <Route exact path="/editor/pano">
        <MainSidebar>
          <OverviewPanel>
            <PageTitleWrapper>
              <PageTitle>
                <FormattedMessage
                  id="editor.panos.title"
                  defaultMessage="Panoramas"
                  description="Title text for panoramas overview"
                />
              </PageTitle>
            </PageTitleWrapper>
            {state.panos.length === 0 ? (
                <NoDataNotice />
              ) : (
                <SidebarList>
                  {state.panos.map((pano: Pano) => (
                    <SidebarListItem
                      key={pano.id}
                      onClick={() => history.push(`/editor/pano/${pano.id}/${pano.scenarios[0].slug}`)}
                    >
                      {pano.title}
                    </SidebarListItem>
                  ))}
                </SidebarList>
              )}
          </OverviewPanel>
        </MainSidebar>
        <MainContent>
          <MainContentPlaceholder />
        </MainContent>
      </Route>
      <Route path="/editor/pano/:id/:scenarioSlug" component={SinglePanoPage} />
    </Switch>
  );
};

export default PanoPage;
