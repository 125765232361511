import { IntlShape } from "react-intl";
import { fromPanoSourceImageUrl } from "../SurveyContext";
import { PanoramaSourceModel } from "../tim-survey/EndUserApi";
import { PanoramaType } from "../tim-survey/enums";
import { getTokenValue } from "../TokenStyles";
import { Hotspot, Pano, PanoLabel, PanoScenario } from "../types";

let origin = "";
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_PROXY_HOST) {
  let proxyUrl = new URL(process.env.REACT_APP_PROXY_HOST);
  // Only change the origin if the orgin doesn't have port (for CORS reasons)
  if (!proxyUrl.port) origin = process.env.REACT_APP_PROXY_HOST.replace(/\/$/, "");
}
export interface BaseXmlProps {
  idletime: number;
  fov: number;
  hlookat: number;
  vlookat: number;
}
export const baseXml = ({ idletime, fov, hlookat, vlookat }: BaseXmlProps) => `
<krpano version="1.21" ${idletime ? `idletime="${idletime}"` : ""}>
	<contextmenu versioninfo="false">
    <item caption="© The Imagineers" />
  </contextmenu>
  <include url="/krpano/webvr.xml" />
  <plugin name="audioplayer"
    url="/krpano/soundinterface.js"
    preload="true"
    volume="1.0"
    mute="false"
    autounlock="true"
  />
  <plugin name="gyro"
    devices="html5"
    keep="true"
    url="/krpano/gyro2.js"
    touch_mode="disablegyro"
    autocalibration="true"
  />
  <display
    autofullscreen="false"
    nofullscreenfallback="true"
  />
  <view
    limitview="range"
    fovmin="20"
    fovmax="100"
    fov="${fov}"
    hlookat="${hlookat}"
    vlookat="${vlookat}"
  />
  <control
    keycodesin="107,187"
    keycodesout="109,189"
  />
  <style
    name="poly_hotspot"
    fillalpha="0"
    fillcolor="${getTokenValue("--color-primary-50").replace("#", "0x")}"
  />
</krpano>
`;

const generateHotspotXml = (hotspots: Hotspot[]) => {
  return hotspots.reduce((acc: string, hotspot: Hotspot) => {
    const generateFn =
      hotspot.type && ["polyline", "polygon"].includes(hotspot.type)
        ? generatePolygonHotspotXml
        : generatePointHotspotXml;

    return acc + generateFn(hotspot);
  }, "");
};

const phantomOpacity = 0.75;

const generatePointHotspotXml = (hotspot: Hotspot) => {
  const label = generateLabelURI(hotspot.title);
  const { yaw, pitch } = hotspot.coordinates[0];
  let width = 30;
  switch (hotspot.type) {
    case "faq":
      width = 25;
      break;
    case "high":
    case "low":
      width = 50;
      break;
  }

  let oy = 0;
  if (hotspot.type === "faq" || hotspot.type === undefined) oy = -21;
  if (hotspot.action) oy = 0;

  // prettier-ignore
  const hotspotInteractivityAttributes = toAttributes({
    alpha: hotspot.phantom ? phantomOpacity : undefined,
    cursor: hotspot.phantom ? "default" : undefined,
    onhover: hotspot.phantom ? undefined : ["tween(scale, 1.1)", hotspot.title ? `tween(hotspot[${hotspot.slug}-text].scale, 1.1)` : ""].join(";"),
    onout: hotspot.phantom ? undefined : ["tween(scale, 1)", hotspot.title ? `tween(hotspot[${hotspot.slug}-text].scale, 1)` : ""].join(";"),
  });

  const hotspotXml = `<hotspot name="${hotspot.slug}"
    url="data:image/svg+xml;utf8,${encodeURIComponent(hotspot.icon!)}"
    width="${width}"
    height="prop"
    ath="${yaw}"
    atv="${pitch}"
    oy="${oy}"
    depth="1500"
    capture="false"
    zorder="51"
    linkurl="${hotspot.url}"
    ${hotspotInteractivityAttributes}
  />`;

  // prettier-ignore
  const hotspotLabelInteractivityAttributes = toAttributes({
    alpha: hotspot.phantom ? phantomOpacity : undefined,
    cursor: hotspot.phantom ? "default" : undefined,
    onhover: hotspot.phantom ? undefined : [`tween(hotspot[${hotspot.slug}].scale, 1.1)`, "tween(scale, 1.1)"].join(";"),
    onout: hotspot.phantom ? undefined : [`tween(hotspot[${hotspot.slug}].scale, 1)`, "tween(scale, 1)"].join(";"),
  });

  const labelXml = hotspot.title
    ? `<hotspot name="${hotspot.slug}-text"
    url="${label.svg}"
    width="${label.width}"
    height="prop"
    ath="${yaw}"
    atv="${pitch}"
    edge="left"
    ox="${25}"
    oy="${hotspot.type === "faq" || hotspot.type === undefined ? -21 : 0}"
    depth="1500"
    capture="false"
    zorder="51"
    ${hotspotLabelInteractivityAttributes}
  />`
    : "";

  return hotspotXml + labelXml;
};

const generatePolygonHotspotXml = (hotspot: Hotspot) => {
  const label = generateLabelURI(hotspot.title);
  const medianYaw = hotspot.coordinates.reduce((a, c) => a + c.yaw, 0) / hotspot.coordinates.length;
  const minPitch = Math.max(...hotspot.coordinates.map((c) => c.pitch));

  const fillColor = getComputedStyle(document.documentElement)
    .getPropertyValue(hotspot.fill?.color ?? "--color-white")
    .replace("#", "0x");
  const fillOpacity = hotspot.fill?.opacity ?? 0;
  const borderColor = getComputedStyle(document.documentElement)
    .getPropertyValue(hotspot.border?.color ?? "--color-black")
    .replace("#", "0x");
  const borderOpacity = hotspot.border?.opacity ?? 0;
  const borderWidth = hotspot.border?.width ?? (hotspot.type === "polyline" ? 3 : 0);

  const hotspotXml = `<hotspot name="${hotspot.slug}"
    ${fillOpacity || !hotspot.allowHighlight ? "" : 'style="poly_hotspot"'}
    polyline="${hotspot.type === "polyline"}"
    fillcolor="${fillColor}"
    ${fillOpacity || !hotspot.allowHighlight ? `fillalpha="${fillOpacity}"` : ""}
    bordercolor="${borderColor}"
    borderalpha="${borderOpacity}"
    borderwidth="${borderWidth}"
    capture="false"
    zorder="${hotspot.type === "polyline" ? 50 : 49}"
    linkurl="${hotspot.url}"
  >
    ${hotspot.coordinates.reduce((acc, coord) => {
      return `${acc}<point ath="${coord.yaw}" atv="${coord.pitch}"/>`;
    }, "")}
  </hotspot>`;

  const topRight = hotspot.coordinates.reduce(
    (a, c) => ({
      yaw: Math.max(a.yaw, c.yaw),
      pitch: Math.min(a.pitch, c.pitch),
    }),
    { yaw: -Infinity, pitch: Infinity }
  );

  const topRightPoint = hotspot.coordinates.reduce((a, c) => {
    const currentClosestDistance = Math.sqrt(
      Math.pow(a.yaw - topRight.yaw, 2) + Math.pow(a.pitch - topRight.pitch, 2)
    );
    const currentDistance = Math.sqrt(
      Math.pow(c.yaw - topRight.yaw, 2) + Math.pow(c.pitch - topRight.pitch, 2)
    );
    return currentDistance < currentClosestDistance ? c : a;
  });

  const badgeSize = 20;
  const badge = hotspot.showBadge
    ? `<hotspot name="${hotspot.slug}-badge"
    html="1"
    type="text"
    css="font-size: ${getTokenValue("--font-size-30")}; font-weight: bold; color: ${getTokenValue(
        "--color-white"
      )}; text-align: center; line-height: ${badgeSize - 1}px;"
    bgroundedge="${badgeSize / 2}"
    bgcolor="${getTokenValue("--color-error-50").replace("#", "0x")}"
    width="${badgeSize}"
    height="${badgeSize}"
    oversampling="5"
    ath="${topRightPoint.yaw}"
    atv="${topRightPoint.pitch}"
    capture="false"
    zorder="51"
  />`
    : "";

  const labelXml = hotspot.title
    ? `<hotspot name="${hotspot.slug}-text"
    url="${label.svg}"
    width="${label.width}"
    height="prop"
    ath="${medianYaw}"
    atv="${minPitch}"
    edge="top"
    oy="5"
    capture="false"
    zorder="51"
  />`
    : "";

  return hotspotXml + badge + labelXml;
};

const generateLabelXml = (labels: PanoLabel[]) => {
  if (labels) {
    return labels.reduce((xml, label) => {
      const panoLabel = generateLabelURI(label.text);
      return (
        xml +
        `<hotspot name="${label.id}"
        url="${panoLabel.svg}"
        width="${panoLabel.width}"
        height="prop"
        ath="${label.yaw}"
        atv="${label.pitch}"
        handcursor="false"
        capture="false"
      />`
      );
    }, "");
  }
  return "";
};

const autoRotateXml = (accel: number, speed: number, fov: number) => `<autorotate
  accel="${accel}"
  speed="${speed}"
  tofov="${fov}"
/>`;

export const customPanoXml = (panoSource: PanoramaSourceModel) => {
  let imageXml = "";
  switch (panoSource.panoType) {
    case PanoramaType.Cube:
      imageXml = `<cube
        url="${fromPanoSourceImageUrl(panoSource.imageUrl)}"
        ${panoSource.levels.length > 1 ? `multires="${Math.min(...panoSource.levels)},${panoSource.levels.slice().sort((a,b) => b-a).join(",")}"` : ""}
      />`;
      break;

    case PanoramaType.Sphere:
      imageXml =`<sphere
        url="${fromPanoSourceImageUrl(panoSource.imageUrl)}"
        ${panoSource.levels.length > 1 ? `multires="${Math.min(...panoSource.levels)},${panoSource.levels.slice().sort((a,b) => b-a).map(s => `${s}x${s/2}x${s}`).join(",")}"` : ""}
      />`;
      break;
  }

  return `<krpano>
    <image>
      ${imageXml}
    </image>
    ${dragHotspot()}
  </krpano>`;
};

export const imagePanoXml = (
  pano: Pano,
  panoScenario: PanoScenario,
  hotspots: Hotspot[],
  panoramaFolder: string,
  intl: IntlShape
) => `<krpano>
  <image>
    <cube
      url="${origin}/panoramas/${panoramaFolder}/${
  panoScenario.resource
}/mres_%s/l%l/%v/l%l_%s_%v_%h.jpg"
      multires="500,3000,1500,500"
    />
  </image>
  <preview
    url="${origin}/panoramas/${panoramaFolder}/${panoScenario.resource}/preview.jpg"
  />
  ${generateHotspotXml(hotspots)}
  ${generateLabelXml(pano.labels)}
  ${generateVrControls(pano, panoScenario, intl)}
  ${pano.isIndoor ? indoorIntro(3) : outdoorIntro(6)}
  ${highlightPolygons(1)}
  ${dragHotspot()}
  ${autoRotateXml(0.5, 5, pano.fov)}
</krpano>
`;

export const videoPanoXml = (
  pano: Pano,
  panoScenario: PanoScenario,
  hotspots: Hotspot[],
  panoramaFolder: string,
  intl: IntlShape
) => {
  const resources = panoScenario.resource.map((resource: string) => {
    return `${origin}/panoramas/${panoramaFolder}/${resource}`;
  });
  return `<krpano>
  <image>
    <sphere url="plugin:video" />
  </image>
  <plugin name="video"
    url.html5="/krpano/videoplayer.js"
    panovideo="true"
    pausedonstart="true"
    videourl="${resources.join("|")}"
    loop="${pano.repeatVideo}"
    volume="1.0"
  />
  ${generateHotspotXml(hotspots)}
  ${generateLabelXml(pano.labels)}
  ${generateVrControls(pano, panoScenario, intl)}
  ${generateVideoControlHotspots()}
  ${highlightPolygons(1)}
  ${pano.isIndoor ? indoorIntro(3) : outdoorIntro(6)}
  ${dragHotspot()}
</krpano>
`;
};

const generateVrControls = (pano: Pano, panoScenario: PanoScenario, intl: IntlShape) => {
  const otherScenarios = pano.scenarios.filter(
    (s) => s !== panoScenario && panoScenario.showInNavigation
  );

  const exitVrVerticalPosition = 50;
  const scenarioSelectionVerticalPosition = 65;

  const exitVRLabel = generateLabelURI(
    intl.formatMessage({
      id: "pano.exit-vr",
      defaultMessage: "Exit VR",
      description: "Hotspot label for exiting VR, only shown while the user is currently in VR.",
    })
  );

  const exitVRButton = `<hotspot name="vr_exit"
    url="${exitVRLabel.svg}"
    width="${exitVRLabel.width}"
    height="prop"
    visible="false"
    distorted="true"
    ath="0"
    atv="${exitVrVerticalPosition}"
    zorder="100"
    onout="tween(scale, 1)"
    onhover="tween(scale, 1.1)"
    onclick="webvr.exitVR()"
    vr="true"
    vr_ui="true"
  />`;

  const scenarioLabels = otherScenarios.map((l) =>
    generateLabelURI(l.title, { fillColor: getTokenValue("--color-primary-30") })
  );

  if (otherScenarios.length) {
    const currentScenarioLabel = generateLabelURI(panoScenario.title, {
      fillColor: getTokenValue("--color-primary-20"),
    });

    const uiSpacing = parseInt(getTokenValue("--space-10"));
    const uiPadding = parseInt(getTokenValue("--space-30"));

    const uiHeight = scenarioLabels.reduce(
      (acc, label) => acc + label.height + uiSpacing,
      currentScenarioLabel.height + 3 * uiPadding
    ); 

    const uiWidth = Math.max(currentScenarioLabel.width, ...scenarioLabels.map(l => l.width)) + 2 * uiPadding;

    const uiRect = {
      left: -uiWidth / 2,
      top: -uiHeight / 2,
      right: uiWidth / 2,
      bottom: uiHeight / 2,
    }

    const uiShape = generatePolygonShapeURI([
      [uiRect.left, uiRect.top],
      [uiRect.right, uiRect.top],
      [uiRect.right, uiRect.bottom],
      [uiRect.left, uiRect.bottom],
    ]);

    const scenarioSwitchUi = `<hotspot name="vr_scenario_switch_ui"
      url="${uiShape.svg}"
      width="${uiShape.width}"
      height="prop"
      visible="false"
      distorted="true"
      enabled="false"
      zorder="0"
      ath="0"
      atv="${scenarioSelectionVerticalPosition}"
      vr="true"
      vr_ui="true"
    />
    <hotspot name="vr_scenario_switch_ui_header_label"
      url="${currentScenarioLabel.svg}"
      width="${currentScenarioLabel.width}"
      height="prop"
      visible="false"
      distorted="true"
      enabled="false"
      zorder="100"
      ath="0"
      atv="${scenarioSelectionVerticalPosition}"
      oy="${uiRect.top + uiPadding}"
      edge="top"
      vr="true"
      vr_ui="true"
    />`;

    const scenarios = new Array<string>();
    scenarioLabels.reduce((lastOy, label, i) => {
      const oy = lastOy + uiSpacing + label.height;

      scenarios.push(`<hotspot name="vr_scenario_switch-${otherScenarios[i].slug}"
        url="${label.svg}"
        width="${label.width}"
        height="${label.height}"
        visible="false"
        distorted="true"
        zorder="100"
        edge="lefttop"
        ath="0"
        atv="${scenarioSelectionVerticalPosition}"
        ox="${uiRect.left + uiPadding}"
        oy="${oy}"
        vr="true"
        vr_ui="true"
      />`);

      return oy;
    }, uiRect.top + 2 * uiPadding);

    return exitVRButton + scenarios.join("") + scenarioSwitchUi;
  } else {
    return exitVRButton;
  }
};

const outdoorIntro = (introTime: number) => {
  const secondaryAnimationTime = Number((introTime * 0.6).toPrecision(2));

  return `<action name="intro">
    Math.max(targetFov, 20, %3);
    Math.min(targetFov, 100);
    sub(starth, %1, 90);

    set(view,
      hlookat=get(starth),
      vlookat=90,
      fovmax=150,
      fov=150,
      camroll=0.000000,
      fisheye=1.00,
      fisheyefovlink=0.50,
      stereographic=true,
      architectural=0.0,
      architecturalonlymiddle=true,
      limitview=auto,
    );
    tween(view.hlookat, %1, ${introTime}, easeInOutQuad);
    delayedcall(
      ${introTime - secondaryAnimationTime},
      tween(view.vlookat, %2, ${secondaryAnimationTime}, easeInOutQuad);
      tween(view.fov, get(targetFov), ${secondaryAnimationTime}, easeInOutQuad, set(view.fovmax, 100));
      tween(view.fisheyefovlink, 0.3, ${secondaryAnimationTime}, easeInOutQuad);
      tween(view.fisheye, 0.0, ${secondaryAnimationTime}, easeInOutQuad);
    );
    delayedcall(${introTime}, events.dispatch(onintrocomplete));
  </action>`;
};

const indoorIntro = (introTime: number) => {
  return `<action name="intro">
    set(targetH, %1);
    set(targetV, %2);
    Math.max(targetFov, 20, %3);
    Math.min(targetFov, 100);

    set(view,
      stereographic=true,
      fovmax=179,
      architectural=1.0,
      fisheye=0.35,
      fisheyefovlink=0.5,
      fov=calc(targetFov + 10),
      vlookat=calc(targetV + 10),
      hlookat=get(targetH),
      limitview=auto
    );

    tween(view.vlookat,  get(targetV), ${introTime / 2}, easeinoutquad);
    tween(view.fov,  get(targetFov), ${introTime}, easeinoutquad, set(view.fovmax, 100));
    tween(view.fisheyefovlink,  0.3, ${introTime}, easeinoutquad);
    tween(view.architectural,   0.0, ${introTime}, easeinoutquad);
    tween(view.fisheye,         0.0, ${introTime}, easeinoutquad);
    delayedcall(${introTime}, events.dispatch(onintrocomplete));
  </action>`;
};

const highlightPolygons = (transitionTime: number) => {
  const fadeInTime = transitionTime / 4;
  const fadeOutTime = transitionTime - fadeInTime;

  return `<action name="highlight_polygons">
    set(transitioning, true);

    forall(hotspot, hs,
      if(hs.style == poly_hotspot AND !hs.storedfillcolor,
        set(hs.storedfillcolor, get(hs.fillcolor));
      );
    );

    tween(
      style[poly_hotspot].fillalpha,
      0.25,
      ${fadeInTime},
      easeOutQuad,
      tween(
        style[poly_hotspot].fillalpha,
        0,
        ${fadeOutTime},
        easeInQuad,
        set(transitioning, false);
      )
    );
    asyncloop(
      transitioning,
      forall(hotspot, hs,
        if(hs.style == poly_hotspot,
          assignstyle(hs, poly_hotspot)
        );
      ),
      forall(hotspot, hs,
        if(hs.style == poly_hotspot,
          assignstyle(hs, poly_hotspot);
          if(hs.storedfillcolor,
            set(hs.fillcolor, get(hs.storedfillcolor));
          );
        );
      );
    );
  </action>`;
};

const dragHotspot = () => `<action name="draghotspot">
  set(control.usercontrol, off);
  txtadd(labelname, get(name), '-text');
  set(text, get(hotspot[get(labelname)]));
  spheretoscreen(ath, atv, hotspotcenterx, hotspotcentery, calc(mouse.stagex LT stagewidth/2 ? l : r));
  sub(drag_adjustx, mouse.stagex, hotspotcenterx);
  sub(drag_adjusty, mouse.stagey, hotspotcentery);
  asyncloop(pressed,
    events.dispatch(dragtick);
    sub(dx, mouse.stagex, drag_adjustx);
    if(text !== null, set(text.dx, get(dx)));
    sub(dy, mouse.stagey, drag_adjusty);
    if(text !== null, set(text.dy, get(dy)));
    screentosphere(dx, dy, ath, atv),
    set(control.usercontrol, all);
  );
</action>`;

const generateVideoControlHotspots = () => {
  return `<hotspot name="vr_toggle_play"
    url=""
    visible="false"
    width="50"
    height="prop"
    ath="0"
    atv="20"
    onout="tween(scale, 1)"
    onhover="tween(scale, 1.1)"
    vr="true"
    vr_ui="true"
  />`;
};

export interface GenerateLabelOptions {
  fontSizeString?: string;
  fontSize?: number;
  fillColor?: string;
  fontFamily?: string;
  shadowColor?: string;
}
export const generateLabelURI = (
  text: string,
  {
    fontSizeString = getTokenValue("--font-size-20"),
    fontSize = parseInt(fontSizeString),
    fillColor = getTokenValue("--color-white"),
    fontFamily = getTokenValue("--font-family-body"),
    shadowColor = getTokenValue("--color-neutral-90"),
  }: GenerateLabelOptions = {}
) => {
  /**
   * Create a temporary element to determine svg's actual size.
   * @todo fix font-families are not considered in some OS and browsers
   */
  const temporaryElement = document.createElement("div");
  temporaryElement.textContent = text;
  temporaryElement.setAttribute(
    "style",
    `
    visibility: hidden;
    font-weight: 600;
    font-size: var(--font-size-30);
    font-family: "sans-serif";
    position: absolute;
    padding: 3px;
    white-space: nowrap;
    box-sizing: border-box;
  `
  );
  document.body.appendChild(temporaryElement);

  const labelWidth = temporaryElement.offsetWidth;
  const labelHeight = temporaryElement.offsetHeight;
  const sharpenFactor = 5;

  document.body.removeChild(temporaryElement);

  const svg = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="${labelWidth * sharpenFactor}"
    height="${labelHeight * sharpenFactor}">
    <text style="text-shadow: 0px 0px 15px ${shadowColor}"
      font-weight="500"
      font-size="${fontSize * sharpenFactor}px"
      font-family="${fontFamily}"
      fill="${fillColor}"
      stroke="none"
      stroke-width="0"
      paint-order="stroke"
      dominant-baseline="middle"
      x="${2 * sharpenFactor}"
      y="${(labelHeight * sharpenFactor) / 2}"
    >
      ${encodeEntities(text)}
    </text>
  </svg>
  `;
  return {
    svg: "data:image/svg+xml;utf8," + encodeURIComponent(svg),
    width: labelWidth,
    height: labelHeight,
  };
};

export const generatePolygonShapeURI = (points: [number, number][]) => {
  const sharpenFactor = 5;

  const minX = Math.min(...points.map(([x]) => x));
  const maxX = Math.max(...points.map(([x]) => x));
  const minY = Math.min(...points.map(([, y]) => y));
  const maxY = Math.max(...points.map(([, y]) => y));

  const width = maxX - minX;
  const height = maxY - minY;

  const shape = `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="${minX} ${minY} ${width} ${height}"
    width="${width * sharpenFactor}"
    height="${height * sharpenFactor}">
    <polygon
      fill="${getTokenValue("--color-neutral-50")}"
      fill-opacity="0.5"
      stroke="${getTokenValue("--color-neutral-30")}"
      stroke-opacity="1"
      stroke-width="1"
      points="${points.map(([x, y]) => `${x},${y}`).join(" ")}"
    />
  </svg>`;

  return {
    svg: "data:image/svg+xml;utf8," + encodeURIComponent(shape),
    width,
  };
};

const toAttributes = <T extends { [key: string]: unknown }>(properties: T): string =>
  Object.entries(properties)
    .filter((entry): entry is [string, string|number|boolean] => ["string", "number", "boolean"].includes(typeof entry[1]))
    .map(([key, value]) => `${key}="${value}"`)
    .join(" ");

const surrogate = document.createElement("textarea");
export const encodeEntities = (str: string): string => {
  surrogate.textContent = str;
  const encoded = surrogate.innerHTML;
  surrogate.textContent = "";
  return encoded;
};

// <!-- videourl="${origin}/panos/${panoramaFolder}/${pano.photoPath}" -->
