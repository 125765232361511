import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useEditState } from "../EditContext";
import { KrpanoElement } from "../../../libs/krpano";
import { PanoScenario, Poi, Scenario, UseStateReturn } from "../../../types";
import { Pano } from "../../../types";
import { imagePanoXml } from "../../../utils/panoXmlUtils";
import { useAppState } from "../../../AppContext";
import { useIntl } from "react-intl";
import EditorKrpano, { krpanoId } from "./EditorKrpano";
import PanoLinkEditor from "./Link/PanoLinkEditor";
import { getCoordinates } from "../../../utils/panoUtils";
import { LinkEditorDialog } from "../EditorStyles";
import {
  EditorData,
  EditorPanoLink,
  EditorPanoPoi,
  Picking,
  SelectedLink,
  areCoordinatesEqual,
  areNumbersEqual,
} from "../../../utils/editorUtils";
import SpinnerWrapper from "../BaseComponents/SpinnerWrapper";
import PanoPickerPlaceholder from "./Link/PanoPickerPlaceholder";
import { useRestAuth } from "../../../hooks/useRestAuth";
import PanoAddButtons from "./PanoAddButtons";
import { EditorScenarioSwitch } from "../BaseComponents/EditorScenarioSwitch";
import { useParams } from "react-router-dom";

interface PanoEditorProps {
  pano: Pano;
}

const PanoEditor: React.FC<PanoEditorProps> = ({ pano }) => {
  const {
    panoLinkRepository,
    panoPoiRepository,
    data,
    editData,
    target,
  } = useEditState();
  const krpano = document.getElementById(krpanoId) as KrpanoElement | undefined;
  const { state } = useAppState();
  const [faded, setFaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const intl = useIntl();
  const [picking, setPicking] = useState(Picking.Off);
  const [selectedLink, setSelectedLink] = useState<SelectedLink>();
  const [linkEditorLoading, setLinkEditorLoading] = useState(false);
  const [linkEditorCoordinatesLoading, setLinkEditorCoordinatesLoading] = useState<boolean>(false);
  const restAuth = useRestAuth();
  const { scenarioSlug } = useParams<{ scenarioSlug: string }>();

  const scenario = useMemo(() => {
    if (scenarioSlug) {
      return pano.scenarios.find(s => s.slug === scenarioSlug);
    }
  }, [pano, scenarioSlug]);

  useEffect(() => {
    setSelectedLink(undefined);
  }, [pano, scenario]);

  const addPanoLink = useCallback(async (coordinates: { x: number; y: number }) => {
    if (state.panos && state.panos.length > 0) {
      const targetPano = state.panos.find(p => p.id !== target && p.scenarios.some(s => s.id === scenario?.id));
      if (!targetPano) return;
      const panoLink: EditorPanoLink = {
        panorama: targetPano.id,
        hotspot_type: "point",
        hotspot_coordinates: [
          {
            pitch: coordinates.y,
            yaw: coordinates.x,
          },
        ],
        pitch: coordinates.y,
        yaw: coordinates.x,
      };
      const links = await panoLinkRepository.create(restAuth, panoLink, pano?.id);
      if (links) {
        setSelectedLink({ type: "pano", item: Math.max(...Object.keys(links).map(Number)) });
        editData<"pano">({
          panorama_location: links,
        });
      }
      return links;
    }
  }, [editData, pano?.id, panoLinkRepository, restAuth, scenario, state.panos, target]);

  const addPoiLink = useCallback(
    async (coordinates: { x: number; y: number }) => {
      const targetPoi = state.pois.find(p => p.id !== target && p.scenarios.some(s => s === scenario?.id));
      if (!targetPoi) return;

      const hotspotCoordinates = [
        {
          pitch: coordinates.y,
          yaw: coordinates.x,
        },
      ];
      
      const poiLink: EditorPanoPoi = {
        poi: targetPoi.id,
        hotspot_type: "point",
        hotspot_coordinates: hotspotCoordinates,
        pitch: coordinates.y,
        yaw: coordinates.x,
      };

      const links = await panoPoiRepository.create(restAuth, poiLink, pano?.id);
      if (links) {
        const index = Object.keys(links).findIndex((key) => {
          const link: EditorPanoPoi = links[key as keyof typeof links] as EditorPanoPoi;
          return (
            link.poi === targetPoi.id &&
            areCoordinatesEqual(link.hotspot_coordinates, hotspotCoordinates) &&
            areNumbersEqual(link.pitch, coordinates.y) &&
            areNumbersEqual(link.yaw, coordinates.x)
          );
        });
        setSelectedLink({ type: "poi", item: index });
        editData<"pano">({
          poi_location: links,
        });
      }
      return links;
    },
    [state.pois, panoPoiRepository, restAuth, pano?.id, target, scenario, editData]
  );

  const addLink = useCallback(async () => {
    if (krpano) {
      setLinkEditorLoading(true);
      setPicking(Picking.Off);
      const coordinates = getCoordinates(krpano);

      if (picking === Picking.Pano) {
        await addPanoLink(coordinates);
      } else {
        await addPoiLink(coordinates);
      }
      setLinkEditorLoading(false);
    }
  }, [krpano, picking, addPanoLink, addPoiLink]);

  const addCoordinate = useCallback(async () => {
    if (krpano && selectedLink) {
      setPicking(Picking.Off);
      setLinkEditorLoading(true);
      const coordinates = getCoordinates(krpano);
      if (selectedLink.type === "pano") {
        const link = (data as EditorData<"pano">).panorama_location[selectedLink.item];
        const hotspot_coordinates = [...link.hotspot_coordinates, { yaw: coordinates.x, pitch: coordinates.y }];
        const hotspot_type = link.hotspot_type === "point" ? "polyline" : link.hotspot_type;
        const links = await panoLinkRepository.update(restAuth, { hotspot_type, hotspot_coordinates }, selectedLink.item, pano.id);
        editData<"pano">({ panorama_location: links});
      } else {
        const link = (data as EditorData<"pano">).poi_location[selectedLink.item];
        const hotspot_coordinates = [...link.hotspot_coordinates, { yaw: coordinates.x, pitch: coordinates.y }];
        const hotspot_type = link.hotspot_type === "point" ? "polyline" : link.hotspot_type;
        const links = await panoPoiRepository.update(restAuth, { hotspot_type, hotspot_coordinates }, selectedLink.item, pano.id);
        editData<"pano">({ poi_location: links});
      }
      setLinkEditorLoading(false);
    }
  }, [data, editData, krpano, pano.id, panoLinkRepository, panoPoiRepository, restAuth, selectedLink]);

  const onHotspotDragged = useCallback(async (event: Event) => {
    if (!(event instanceof CustomEvent) || !restAuth) return;
    const draggedHotspot = event.detail;
    const match = draggedHotspot.name.match(/editor-(\w+)-(\d+)(?:-(\d+))?/);
    if (!match) return;

    const linkType = match[1];
    const linkIndex = Number(match[2]);
    const vertexIndex = match[3] ? Number(match[3]) : null;

    if (linkIndex !== selectedLink?.item) {
      setLinkEditorLoading(true);
      setSelectedLink({ type: linkType, item: linkIndex });
    } else {
      setLinkEditorCoordinatesLoading(true);
    }

    let link = {};
    if (vertexIndex !== null) {
      const oldLink = linkType === "pano" ? (data as EditorData<"pano">).panorama_location[linkIndex] : (data as EditorData<"pano">).poi_location[linkIndex];
      link = {
        hotspot_coordinates: oldLink.hotspot_coordinates.map((coord, index) =>
          index === vertexIndex ? { pitch: draggedHotspot.atv, yaw: draggedHotspot.ath } : coord
        )
      };
      if (vertexIndex === 0) {
        link = { ...link, pitch: draggedHotspot.atv, yaw: draggedHotspot.ath };
      }
    } else {
      link = {
        yaw: draggedHotspot.ath, pitch: draggedHotspot.atv,
        hotspot_coordinates: [{ pitch: draggedHotspot.atv, yaw: draggedHotspot.ath }]
      };
    }

    if (linkType === "pano") {
      const links = await panoLinkRepository.update(restAuth, link, linkIndex, pano.id);
      editData<"pano">({ panorama_location: links });
    } else {
      const links = await panoPoiRepository.update(restAuth, link, linkIndex, pano.id);
      editData<"pano">({ poi_location: links });
    }
    if (linkIndex !== selectedLink?.item) {
      setLinkEditorLoading(false);
    } else {
      setLinkEditorCoordinatesLoading(false);
    }
  }, [data, editData, pano.id, panoLinkRepository, panoPoiRepository, restAuth, selectedLink?.item]);

  useLayoutEffect(() => {
    document.addEventListener("hotspotdragged", onHotspotDragged);
    return () => document.removeEventListener("hotspotdragged", onHotspotDragged);
  }, [onHotspotDragged]);

  useEffect(() => {
    krpano?.set("events[editmode].onclick", picking !== Picking.Off ? picking === Picking.Coordinate ? addCoordinate : addLink : null);
  }, [picking, krpano, addLink, addCoordinate]);

  useEffect(() => {
    async function fetchLinks() {
      if (target) {
        const panoLinks = await panoLinkRepository.get(restAuth, target);
        const poiLinks = await panoPoiRepository.get(restAuth, target);
        if (panoLinks || poiLinks) {
          editData<"pano">({ panorama_location: panoLinks, poi_location: poiLinks });
          setDataLoaded(true);
        }
      }
    }
    fetchLinks();
  }, [editData, panoLinkRepository, panoPoiRepository, restAuth, target]);

  const onCloseEditor = () => {
    setSelectedLink(undefined);
  };

  return (
    <SpinnerWrapper loading={!dataLoaded && !!scenario}>
      { scenario && <EditorKrpano
        showFade={false}
        fade={[faded, setFaded] as UseStateReturn<boolean>}
        hlookat={0}
        vlookat={0}
        fov={100}
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        pano={pano}
        scenario={scenario}
        xml={imagePanoXml(pano!, scenario!, [], state.panoramaFolder, intl)}
      >
        {(krpano) => (
          <>
          <LinkEditorDialog>
            <SpinnerWrapper loading={linkEditorLoading}>
              {picking !== Picking.Off && <PanoPickerPlaceholder setPicking={setPicking} />}
              {picking === Picking.Off && (
                <>
                  {selectedLink ? (
                    <PanoLinkEditor
                      setPicking={setPicking}
                      setSelectedLink={setSelectedLink}
                      selectedLink={selectedLink}
                      coordinateInputsLoading={linkEditorCoordinatesLoading}
                      scenario={scenario}
                      onCloseEditor={onCloseEditor}
                    />
                  ) : (
                    <PanoAddButtons setPicking={setPicking} scenario={scenario} />
                  )}
                </>
              )}
            </SpinnerWrapper>
          </LinkEditorDialog>
          { pano && <EditorScenarioSwitch item={pano} selectedScenario={scenario} />}
          </>
        )}
      </EditorKrpano> }
    </SpinnerWrapper>
  );
};
export default PanoEditor;