import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppState } from "../../AppContext";
import classNames from "classnames";
import { ScenarioSwitchContainer, ScenarioLink, ScenarioLinkList } from "./PanoStyles";
import { useRouteState } from "../../hooks/useRouteState";
import { Pano } from "../../types";
import { useUpdateStateUrl } from "../../hooks/useUpdateStateUrl";
import { useSessionStorage } from "../../hooks/useStorage";
import { useEditState } from "../Editor/EditContext";
import { useSetPanoState } from "../../hooks/useSetPanoState";
import { useContentUrl } from "../../hooks/useContentUrl";
import { useHistory } from "react-router-dom";
import { KrpanoElement } from "../../libs/krpano";
import { krpanoId } from "../../utils/panoUtils";

type PanoScenarioSwitchProps = {
  pano: Pano;
  teasing: boolean;
};

export function PanoScenarioSwitch({ pano, teasing }: PanoScenarioSwitchProps) {
  const { state } = useAppState();
  const setPanoState = useSetPanoState();
  const routeState = useRouteState();
  const updateStateUrl = useUpdateStateUrl();
  const [disablePanoScenarioTeaser, setDisablePanoScenarioTeaser] = useSessionStorage(
    "disablePanoScenarioTeaser"
  );
  const contentUrl = useContentUrl();
  const history = useHistory();
  const [switchScenario, setSwitchScenario] = useState<string | null>(null);

  useEffect(() => {
    if (switchScenario) {
      setSwitchScenario(null);
      history.push(updateStateUrl({ scenarioSlug: switchScenario }, contentUrl(), true));
    }
  }, [history, contentUrl, switchScenario, setSwitchScenario, updateStateUrl]);

  const storeScenarioSwitched = useCallback(
    (slug: string) => {
      if (routeState.activeScenarioSlug !== slug && disablePanoScenarioTeaser === null)
        setDisablePanoScenarioTeaser("true");
    },
    [routeState.activeScenarioSlug, disablePanoScenarioTeaser, setDisablePanoScenarioTeaser]
  );

  const scenarioLinks = useMemo(() => {
    return pano.scenarios
      .map((scenario) => state.scenarios.find((s) => scenario.slug === s.slug)!)
      .filter((scenario) => scenario && (scenario.showInMenu))
      .map((scenario) => (
        <ScenarioLink
          key={scenario.slug}
          className={classNames({
            active:
              scenario.slug ===
              (teasing ? state.teaseScenario?.slug : routeState.activeScenarioSlug),
          })}
          onClick={() => {
            const krpano = document.getElementById(krpanoId) as KrpanoElement | null;
            storeScenarioSwitched(scenario.slug);
            if (krpano) {
              setPanoState(krpano.get("view"));
            }
            // Ensure that the scenario will switch upon the next render with the updated panostate
            setSwitchScenario(scenario.slug);
          }}
        >
          {scenario.title}
        </ScenarioLink>
      ));
  }, [
    state.scenarios,
    pano.scenarios,
    routeState.activeScenarioSlug,
    state.teaseScenario?.slug,
    storeScenarioSwitched,
    teasing,
    setPanoState,
    setSwitchScenario,
  ]);

  if (scenarioLinks.length < 2) return null;

  return (
    <ScenarioSwitchContainer>
      <ScenarioLinkList>{scenarioLinks}</ScenarioLinkList>
    </ScenarioSwitchContainer>
  );
}
