import React from "react";
import { SideBarTopActionButton, OverviewPanel, PageTitle } from "../EditorStyles";
import { FormattedMessage } from "react-intl";
import { LayerGroup } from "../../../types";

interface MapMetaDataEditorProps {
  map: LayerGroup | undefined;
}

const MapMetaDataEditor: React.FC<MapMetaDataEditorProps> = ({ map }) =>  {
  return (
    // Hier komen metadata inputs
    <p></p>
  );
};

export default MapMetaDataEditor;
