import React, { useCallback } from "react";
import { Marker as MapMarker } from "react-map-gl";
import { MarkerIconStyles } from "../../Map/MapStyles";
import { markerSVGSwitch } from "../../../shared/components/MarkerImages";
import { useEditState } from "../EditContext";
import { EditorData, EditorMapPano, SelectedLink } from "../../../utils/editorUtils";
import { MapPoi } from "../../../types";
import { useRestAuth } from "../../../hooks/useRestAuth";
import { MapPointLabel } from "../EditorStyles";

interface EditorPanoMarkerProps {
  index: number;
  point: EditorMapPano;
  selectedLink: SelectedLink | undefined;
  setSelectedLink: React.Dispatch<React.SetStateAction<SelectedLink | undefined>>;
  setLinkEditorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkEditorCoordinatesLoading: React.Dispatch<React.SetStateAction<boolean>>;
  unlinked?: boolean;
}

const EditorPanoMarker: React.FC<EditorPanoMarkerProps> = ({
  index,
  point,
  selectedLink,
  setSelectedLink,
  setLinkEditorLoading,
  setLinkEditorCoordinatesLoading,
  unlinked = false,
}) => {
  const { mapPanoRepository, target, editData, data } = useEditState();
  const restAuth = useRestAuth();
  const disabled = unlinked || (selectedLink && (selectedLink.type !== 'pano' || selectedLink.item !== point.pano));

  const handleDragEnd = useCallback(
    async (event) => {
      if (restAuth) {
        if (selectedLink?.item !== index) {
          setLinkEditorLoading(true);
          setSelectedLink({ type: "pano", item: point.pano });
        } else {
          setLinkEditorCoordinatesLoading(true);
        }
        const lat = event.lngLat.lat;
        const long = event.lngLat.lng;  
        const links = await mapPanoRepository.update(restAuth, { lat, long }, point.pano);
        editData<"map">({ panorama_location: links });
        if (selectedLink?.item !== index) {
          setLinkEditorLoading(false);
        } else {
          setLinkEditorCoordinatesLoading(false);
        }
      }
    },
    [editData, index, mapPanoRepository, point.pano, restAuth, selectedLink?.item, setLinkEditorCoordinatesLoading, setLinkEditorLoading, setSelectedLink]
  );

  return (
    <MapMarker
      latitude={point.lat}
      longitude={point.long}
      offset={[-18, -18]}
      anchor="top-left"
      draggable={target === undefined}
      onDragEnd={handleDragEnd}
      onClick={async () => {
        if (!target) {
          setSelectedLink({ type: "pano", item: point.pano });
        } else {
          if (restAuth) {
            if (unlinked) {
              const unlinked_links = (data as EditorData<"map">).unlinked_panorama_location.filter(
                (_, i) => i !== index
              );
              const links = [...(data as EditorData<"map">).panorama_location, point];
              editData<"map">({
                panorama_location: links,
                unlinked_panorama_location: unlinked_links,
              });
              await mapPanoRepository.create(restAuth, { pano: point.pano }, target);
            } else {
              const links = (data as EditorData<"map">).panorama_location.filter(
                (_, i) => i !== index
              );
              const unlinked_links = [
                ...(data as EditorData<"map">).unlinked_panorama_location,
                point,
              ];
              editData<"map">({
                panorama_location: links,
                unlinked_panorama_location: unlinked_links,
              });
              await mapPanoRepository.delete(restAuth, point.pano, target);
            }
          }
        }

      }}
    >
      <div style={{ display: "flex" }}>
        <MarkerIconStyles>
          {markerSVGSwitch(
            { type: "pano" } as MapPoi,
            false,
            disabled ? "--color-neutral-50" : point.color,
            disabled ? 0.4 : 1
          )}
        </MarkerIconStyles>
        <MapPointLabel $disabled={disabled} $circle={true}>{point.title}</MapPointLabel>
      </div>
    </MapMarker>
  );
};

export const MemoizedEditorPanoMarker = React.memo(EditorPanoMarker);
