import { MainContentPlaceholderWrapper } from "../EditorStyles";
import { FormattedMessage } from "react-intl";

const MainContentPlaceholder = () => {
  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <img style={{height: "120px"}} src="https://theimagineers.com/wp-content/uploads/2022/03/beeldmerk.svg" alt="logo"/>
    </div>
    // <MainContentPlaceholderWrapper>
    //   <h1>Ik ben beschrijvende tekst</h1>
    //   <p>Hier komt uitleg oid</p>
    // </MainContentPlaceholderWrapper>
  );
};

export default MainContentPlaceholder;
