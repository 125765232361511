import React, { useMemo } from "react";

type Props = {
  direction?: number;
  type: "pano" | "video" | "vcr";
  height?: "low" | "high";
  selected?: boolean;
  color?: string | null;
  selectedColor?: string | null;
  opacity?: number;
};

export const RotationMarker = ({
  direction,
  type,
  height,
  selected = false,
  color,
  selectedColor,
  opacity = 1,
}: Props) => {
  const icon = useMemo(() => {
    switch (type) {
      case "pano":
        return (
          <g transform="translate(15 15)">
            <circle fill="#FFF" fillRule="nonzero" cx="8" cy="8" r="2.1" />
            <path
              d="M6 1.3L4.8 2.7H2.7c-.8 0-1.4.6-1.4 1.3v8c0 .7.6 1.3 1.4 1.3h10.6c.8 0 1.4-.6 1.4-1.3V4c0-.7-.6-1.3-1.4-1.3h-2L10 1.3H6zm2 10a3.3 3.3 0 110-6.6 3.3 3.3 0 010 6.6z"
              fill="#FFF"
              fillRule="nonzero"
            />
            <path d="M0 0h16v16H0z" />
          </g>
        );
      case "video":
        return (
          <g>
            <path d="M15 15h16v16H15z" />
            <path
              d="M23 16.3a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4zM21.7 26v-6l4 3-4 3z"
              fill="#FFF"
            />
          </g>
        );
      case "vcr":
        return (
          <g fill="#fff">
            {/* @TODO: Remove rejected icons */}

            {/* Home */}
            {/* <path d="M3.333 17.5V7.521L10 2.5L16.667 7.521V17.5H11.25V11.854H8.729V17.5Z" transform="translate(13 12)" /> */}

            {/* Meeting Room */}
            {/* <path d="M3 21v-2h2V3h10v1h4v15h2v2h-4V6h-2v15Zm9-9q0-.425-.287-.713Q11.425 11 11 11t-.712.287Q10 11.575 10 12t.288.712Q10.575 13 11 13t.713-.288Q12 12.425 12 12Z" transform="translate(11 11)" /> */}

            {/* Door Sliding */}
            {/* <path d="M9 13q.425 0 .713-.288Q10 12.425 10 12t-.287-.713Q9.425 11 9 11t-.712.287Q8 11.575 8 12t.288.712Q8.575 13 9 13Zm6 0q.425 0 .713-.288Q16 12.425 16 12t-.287-.713Q15.425 11 15 11t-.712.287Q14 11.575 14 12t.288.712Q14.575 13 15 13ZM3 21v-2h1V5q0-.825.588-1.413Q5.175 3 6 3h5.5v16h1V3H18q.825 0 1.413.587Q20 4.175 20 5v14h1v2Z" transform="translate(11 11)" /> */}

            {/* Groups */}
            <path
              d="M0 18v-1.575q0-1.1 1.1-1.763Q2.2 14 4 14q.325 0 .613.025.287.025.562.075-.35.5-.513 1.075Q4.5 15.75 4.5 16.4V18Zm6 0v-1.6q0-1.625 1.663-2.638Q9.325 12.75 12 12.75q2.7 0 4.35 1.012Q18 14.775 18 16.4V18Zm13.5 0v-1.6q0-.65-.175-1.225-.175-.575-.5-1.075.275-.05.563-.075Q19.675 14 20 14q1.8 0 2.9.662 1.1.663 1.1 1.763V18ZM4 13q-.825 0-1.412-.588Q2 11.825 2 11t.588-1.413Q3.175 9 4 9t1.412.587Q6 10.175 6 11q0 .825-.588 1.412Q4.825 13 4 13Zm16 0q-.825 0-1.413-.588Q18 11.825 18 11t.587-1.413Q19.175 9 20 9q.825 0 1.413.587Q22 10.175 22 11q0 .825-.587 1.412Q20.825 13 20 13Zm-8-1q-1.25 0-2.125-.875T9 9q0-1.25.875-2.125T12 6q1.25 0 2.125.875T15 9q0 1.25-.875 2.125T12 12Z"
              transform="translate(11 10)"
            />
          </g>
        );
    }
  }, [type]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 46 46" opacity={opacity}>
      <defs>
        <ellipse id="shadow-shape" cx="23" cy="30.5" rx="14" ry="8.5" />
        <filter
          x="-57.1%"
          y="-82.4%"
          width="214.3%"
          height="288.2%"
          filterUnits="objectBoundingBox"
          id="shadow"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.0527333151 0 0 0 0 0.101171988 0 0 0 0 0.396993886 0 0 0 0.51685424 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>

      <g fill="none" transform="translate(0 0)">
        <use filter="url(#shadow)" href="#shadow-shape" fill="#000" />
        {direction !== undefined && (
          <g transform={`translate(0, 0) rotate(${direction + (13 - 90)} 23 23)`}>
            <path
              d="M9.88 13.92a16 16 0 1026.8 17.4l8.7-13.4-13.4-8.7a16 16 0 00-22.1 4.7z"
              fill="white"
            />
          </g>
        )}
        <circle fill="#FFF" cx="23" cy="23" r="16" />
        <circle
          fill={
            selected
              ? selectedColor ?? "var(--color-primary-70)"
              : color ?? "var(--color-primary-50)"
          }
          cx="23"
          cy="23"
          r="14"
        />
        {height === "high" && (
          <path
            d="M12 32c1.6-.9 3-1.2 4-.9 1 .3 2 .8 3 1.7a8 8 0 014-1c1.6 0 3 .4 4 1 1-1 2-1.5 3-1.7 1-.2 2.4.1 4 1-3.6 3.7-7.3 5.6-11 5.6s-7.3-1.9-11-5.6z"
            fill="#FFF"
          />
        )}
        {icon}
      </g>
    </svg>
  );
};
