import { useRef, useEffect } from "react";
import {InlinePopupContainer} from "../EditorStyles";

interface InlinePopupProps {
  onClose: () => void;
  children: React.ReactNode;
}

const InlinePopup: React.FC<InlinePopupProps> = ({ onClose, children }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);
  
  return (
    <InlinePopupContainer ref={popupRef}>
      {children}
    </InlinePopupContainer>
  );
};

export default InlinePopup;