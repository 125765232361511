import { decodeEntities } from "@wordpress/html-entities";
import { IntlShape } from "react-intl";
import {
  createContext,
  destroyContext,
  domToCanvas,
  Options as ModernScreenshotOptions,
} from "modern-screenshot";
import { MarkerTypes } from "../shared/components/MarkerImages";
import { WordpressPoi, Poi, LayerGroup, Pano, MapPoi, PoiTypes, Scenario } from "../types";
import { upsert } from "./areaUtils";
import { getTokenValue } from "../TokenStyles";

// eslint-disable-next-line import/no-webpack-loader-syntax
const workerUrl: string = require("url-loader!modern-screenshot/worker").default;

function transformWordpressPoi(
  data: WordpressPoi,
  layerGroups: LayerGroup[],
  panos: Pano[],
  allScenarios: Scenario[],
  lastVisit?: Date | null
): Poi {
  const latitude = Number(data.acf.map_location?.latitude) || undefined;
  const longitude = Number(data.acf.map_location?.longitude) || undefined;
  const scenarios = data.acf.scenarios || [];
  const panoLocations = data.acf.panorama_location;
  const image = data.acf.image;
  const showBadge = !!lastVisit && !!data.acf.allow_badge && new Date(data.modified) > lastVisit;

  if (data.acf.map_location && latitude !== undefined && longitude !== undefined) {
    const layers = data.acf.map_location.layers || [];

    // Add pois to scenario's in the map
    layerGroups
      .filter((l) => layers.includes(l.id))
      .forEach((layerGroup) => {
        layerGroup.scenarios
          .filter((s) => data.acf.all_scenarios || scenarios.includes(s.id))
          .forEach((scenario) => {
            upsert(["slug", "type"], scenario.pois, {
              slug: data.slug || `draft-${data.id}`,
              latitude: latitude,
              longitude: longitude,
              title: decodeEntities(data.title.rendered),
              type: data.acf.type,
              color: data.acf.map_location!.color,
            });
          });
      });
  }

  if (panoLocations && panos) {
    panoLocations.forEach((panoLocation) => {
      const pano = panos.find((pano) => pano.id === panoLocation.panorama);

      if (!pano) return;

      const panoScenarios = pano.scenarios.filter(
        (scenario) => data.acf.all_scenarios || scenarios.includes(scenario.id)
      );

      if (panoScenarios.length) {
        const base = {
          type: data.acf.type,
          slug: data.slug || `draft-${data.id}`,
          excerpt: data.excerpt.rendered,
          title: decodeEntities(data.title.rendered),
          showBadge,
        };
        if (!panoLocation.hotspot_type || panoLocation.hotspot_type === "point") {
          panoScenarios.forEach((scenario) => {
            upsert(["slug", "coordinates"], scenario.pois, {
              ...base,
              hotspotType: "point",
              coordinates: [
                {
                  yaw: Number(panoLocation.yaw),
                  pitch: Number(panoLocation.pitch),
                },
              ],
              color: panoLocation.fill_color,
            });
          });
        } else if (
          panoLocation.hotspot_type === "polyline" ||
          panoLocation.hotspot_type === "polygon"
        ) {
          const coordinates = panoLocation.hotspot_coordinates.map((c) => ({
            yaw: Number(c.yaw),
            pitch: Number(c.pitch),
          }));

          const border = {
            color: panoLocation.border_color || "--color-black",
            opacity:
              panoLocation.border_color && panoLocation.hasOwnProperty("border_opacity")
                ? Number(panoLocation.border_opacity)
                : Number(!!panoLocation.border_color),
            width: panoLocation.hasOwnProperty("border_width")
              ? Number(panoLocation.border_width)
              : 1,
          };

          if (panoLocation.hotspot_type === "polyline") {
            panoScenarios.forEach((scenario) => {
              upsert(["slug", "coordinates"], scenario.pois, {
                ...base,
                coordinates,
                border,
                hotspotType: "polyline",
              });
            });
          } else {
            panoScenarios.forEach((scenario) => {
              upsert(["slug", "coordinates"], scenario.pois, {
                ...base,
                coordinates,
                border,
                hotspotType: "polygon",
                fill: {
                  color: panoLocation.hasOwnProperty("fill_color")
                    ? panoLocation.fill_color
                    : "--color-white",
                  opacity: panoLocation.hasOwnProperty("fill_opacity")
                    ? Number(panoLocation.fill_opacity)
                    : 0,
                },
                allowHighlight: true,
              });
            });
          }
        }
      }
    });
  }

  return {
    id: data.id,
    latitude,
    longitude,
    title: decodeEntities(data.title.rendered),
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    image,
    slug: data.slug || `draft-${data.id}`,
    featuredMedia: data.featured_media,
    type: data.acf.type,
    color: data.acf.map_location?.color,
    status: data.status,
    showBadge,
    scenarios: data.acf.all_scenarios ? allScenarios.map(scenario => scenario.id) : (data.acf.scenarios ? data.acf.scenarios : []),
  };
}

export function transformWordpressPois(
  data: WordpressPoi[],
  layerGroups: LayerGroup[],
  panos: Pano[],
  allScenarios: Scenario[],
  lastVisit?: Date | null
) {
  return data.map((poi) => transformWordpressPoi(poi, layerGroups, panos, allScenarios, lastVisit));
}

export const generateLabelsGeoJson = (pois: MapPoi[]): GeoJSON.FeatureCollection => ({
  type: "FeatureCollection",
  features: pois.map((poi) => ({
    type: "Feature",
    properties: {
      title: poi.type === "question" ? "Denk mee" : poi.title,
      slug: poi.slug,
      type: poi.type,
      description: poi.type === "question" ? poi.title : "",
      phantom: poi.phantom,
    },
    geometry: {
      type: "Point",
      coordinates: [poi.longitude, poi.latitude],
    },
  })),
});

const labelTranslations: { [key in PoiTypes]: [number, number] } = {
  pano: [5, 5],
  info: [0, -17],
  default: [0, -17],
  question: [0, -17],
  video: [5, 5],
  faq: [5, 5],
  edit: [5, 5],
  vcr: [5, 5],
};

const translatePoint = (point: [number, number], type: MarkerTypes) => {
  return point.map((n, i) => n - labelTranslations[type][i]) as [number, number];
};

type LayerFilter = [string, ...[string, ...any[]][]];

export const poiLabelLayer = (type: MarkerTypes, phantom = false) => {
  const filter: LayerFilter = [
    "all",
    [
      "in",
      ["get", "type"],
      ["literal", [MarkerTypes.Info, MarkerTypes.Default, MarkerTypes.Question]],
    ],
  ];
  let textRadialOffset = 1.3;
  const id = `${type}${phantom ? "Phantom" : ""}LabelLayer`;
  if (type === MarkerTypes.Pano) {
    filter.splice(1, 1, [
      "in",
      ["get", "type"],
      ["literal", [MarkerTypes.Pano, MarkerTypes.Vcr, MarkerTypes.Video]],
    ]);
    textRadialOffset = 1.5;
  }

  if (phantom) {
    filter.push(["==", ["get", "phantom"], ["literal", true]]);
  } else {
    filter.push(["any", ["==", ["get", "phantom"], ["literal", false]], ["!", ["has", "phantom"]]]);
  }

  return {
    id: id,
    type: "symbol",
    source: "labels",
    filter,
    layout: {
      "text-field": [
        "format",
        ["get", "title"],
        { "font-scale": 1 },
        "\n",
        {},
        ["get", "description"],
        {
          "font-scale": 0.8,
          "text-font": ["literal", ["Roboto Medium"]],
        },
      ],
      "text-variable-anchor": ["left", "right"],
      "text-size": 15,
      "text-radial-offset": textRadialOffset,
      "text-justify": "auto",
      "text-font": ["Roboto Medium"],
      // 'text-optional': true,
      // "icon-image": ["concat", ["get", "icon"], "-15"],
      // "icon-allow-overlap": true,
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-translate": labelTranslations[type],
      "text-halo-color": "#191825",
      "text-halo-width": 1.25,
      "text-halo-blur": 0.625,
      "text-opacity": phantom ? 0.75 : 1,
    },
  };
};

export const getLabelsOnPoint = (point: [number, number], map: mapboxgl.Map) => {
  const poiLabels = map.getLayer("poiLabelLayer")
    ? map.queryRenderedFeatures(translatePoint(point, MarkerTypes.Default), {
        layers: ["poiLabelLayer"],
      })
    : [];
  const panoLabels = map.getLayer("panoLabelLayer")
    ? map.queryRenderedFeatures(translatePoint(point, MarkerTypes.Pano), {
        layers: ["panoLabelLayer"],
      })
    : [];
  return Array.from(new Set([...poiLabels, ...panoLabels]));
};

// TODO: determine what to do with this
// interface MediaData {
//   url: string;
//   width: number;
//   height: number;
//   top: number;
//   left: number;
// }

const imageBlobUrlMap = new Map<string, string>();

const loadImage = async (img: HTMLImageElement, url?: string) => {
  if (img.complete && url === img.currentSrc) return;

  const urlToLoad = url ?? (img.currentSrc || img.src);

  img.removeAttribute("srcset");
  img.removeAttribute("src");

  return new Promise<void>((resolve) => {
    const resolver = () => {
      img.removeEventListener("load", resolver);
      resolve();
    };
    img.addEventListener("load", resolver);
    img.setAttribute("src", urlToLoad);
    img.setAttribute("loading", "eager");
  });
};

const fetchImageBlobUrl = async (img: HTMLImageElement) => {
  const srcUrl = new URL(img.currentSrc || img.src);
  if (srcUrl.protocol === "http:") srcUrl.protocol = "https:";

  const src = srcUrl.toString();
  if (imageBlobUrlMap.has(src)) return imageBlobUrlMap.get(src)!;

  const res = await fetch(src);
  const blob = await res.blob();
  const url = URL.createObjectURL(blob);
  imageBlobUrlMap.set(src, url);
  return url;
};

export const toHotspot = async (container: HTMLElement, intl: IntlShape, useWorker = true) => {
  const imageRenderingMap = new Map<Node, HTMLImageElement>();

  for (const img of container.querySelectorAll("img")) {
    const clone = img.cloneNode() as HTMLImageElement;
    imageRenderingMap.set(clone, img);
    img.replaceWith(clone);

    try {
      const blobUrl = await fetchImageBlobUrl(clone);
      await loadImage(clone, blobUrl);
    } catch (e) {
      var vrError = document.createElement("blockquote");
      vrError.classList.add("vr-warning");
      vrError.textContent = intl.formatMessage({
        id: "vr.image-error",
        defaultMessage: "Image failed to load, please try to view outside of VR",
        description: "Message shown in place of an image that failed to load in VR",
      });

      imageRenderingMap.set(vrError, img);
      clone.replaceWith(vrError);
      console.warn(e);

      continue;
    }
  }

  const settings: ModernScreenshotOptions = {
    font: {
      cssText: getTokenValue("--font-family-body"),
    },
    style: {
      inset: "auto", // Remove spacing used by react rendering
    },
    backgroundColor: getTokenValue("--color-neutral-10"), // Not white, as white is the color of the vr cursor
    features: {
      copyScrollbar: false,
      removeAbnormalAttributes: false,
    },
    onCloneNode: (cloned) => {
      if (cloned instanceof HTMLElement) {
        cloned.classList.remove("off-screen");

        const figures = [...cloned.querySelectorAll("figure")];

        figures.forEach((f) => {
          f.replaceWith(...f.children);
        });

        for (const clonedEl of [
          ...cloned.querySelectorAll("iframe"),
          ...cloned.querySelectorAll("video"),
        ]) {
          const unsupportedDataReplacement = clonedEl.ownerDocument!.createElement("blockquote");
          unsupportedDataReplacement.classList.add("vr-warning");
          unsupportedDataReplacement.textContent = intl.formatMessage({
            id: "vr.unsupported-content",
            defaultMessage:
              "This section contains content which isn't supported in VR, please view it outside of VR.",
            description: "Message shown in place of unsupported VR content.",
          });

          clonedEl.replaceWith(unsupportedDataReplacement);
        }
      }
    },
  };

  let image: HTMLCanvasElement;

  if (useWorker) {
    const domToImageContext = await createContext(container, {
      ...settings,
      workerUrl,
    });

    image = await domToCanvas(domToImageContext);
    destroyContext(domToImageContext);
  } else {
    image = await domToCanvas(container, settings);
  }

  for (const replaced of container.querySelectorAll("img, blockquote.vr-warning")) {
    const img = imageRenderingMap.get(replaced);

    if (img) {
      replaced.replaceWith(img);
    }
  }

  return image;
};
