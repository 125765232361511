import { AxiosRequestConfig } from "axios";
import { LayerGroup } from "../../../types";
import { ICanRead } from "./ICanRead";
import { Repository } from "./Repository";

export class MapRepository extends Repository implements ICanRead<LayerGroup> {
  async get(restAuth: AxiosRequestConfig | undefined): Promise<LayerGroup[]> {
    const data = await this.api.get(restAuth, "/wp/v2/layergroups");

    if (data) {
      const panos: LayerGroup[] = data.map((item: any) => ({
        id: item.id,
        title: item.title.rendered,
      }));
      return panos;
    }
    return [];
  }

  async single(restAuth: AxiosRequestConfig | undefined, id: number): Promise<LayerGroup> {
    const wp_data = await this.api.single(restAuth, `/wp/v2/layergroups/${id}`);
    const acf = wp_data.acf;
    
    if (wp_data && acf) {
      const pano: LayerGroup = {
        id: id,
        title: wp_data.title.rendered,
        content: wp_data.content,
        excerpt: wp_data.excerpt,
        slug: wp_data.slug,
        showInMenu: acf.show_in_menu,
        areas: [],
        scenarios: acf.mapbox_layer_groups[0].scenarios,
        disableLegendPopup: acf.disable_legend_popup,
        bounds: acf.bouning_box,
        status: wp_data.status,
      }
      return pano;
    }

    return {} as LayerGroup;
  }

  async update(restAuth: AxiosRequestConfig | undefined, item: LayerGroup) {
    return item;
  }
}