import { MainContentPlaceholderWrapper } from "../EditorStyles";
import { FormattedMessage } from "react-intl";

const NoDataNotice = () => {
  return (
    <p>No data available.</p>
  );
};

export default NoDataNotice;
