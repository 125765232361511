import { useEffect, useState } from "react";
import {
  MainSidebar,
  OverviewPanel,
  PageTitle,
  MainContent,
  SideBarTopActionButton,
  PageTitleWrapper,
} from "../EditorStyles";
import MapMetaDataEditor from "./MapMetaDataEditor";
import { useEditState } from "../EditContext";
import MapEditor from "./MapEditor";
import { ReactComponent as ArrowLeftIcon } from "./../../../images/editor-icons/arrow-left.svg";
import { LayerGroup } from "../../../types";
import SpinnerWrapper from "../BaseComponents/SpinnerWrapper";
import { useRestAuth } from "../../../hooks/useRestAuth";
import { useHistory, useParams } from "react-router-dom";
import { useAppState } from "../../../AppContext";

const SingleMapPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [map, setMap] = useState<LayerGroup>();
  const { target, setTarget, mapRepository } = useEditState();
  const restAuth = useRestAuth();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { state } = useAppState();

  useEffect(() => {
    if (id) {
      setTarget(Number(id));
    }
  }, [id, setTarget]);

  useEffect(() => {
    const fetchData = async () => {
      if (target && restAuth) {
        setLoading(true);
        const data = state.map.layerGroups.find((map) => map.id === target);
        setMap(data);
        setLoading(false);
      }
    };

    fetchData();
  }, [mapRepository, restAuth, state.map.layerGroups, target]);

  return (
    <>
      <MainSidebar>
        <OverviewPanel>
        <SideBarTopActionButton onClick={() => history.push("/editor/map")}>
        <ArrowLeftIcon />
          </SideBarTopActionButton>
          <SpinnerWrapper loading={loading}>
            <PageTitleWrapper>
              <PageTitle>{map?.title}</PageTitle>
            </PageTitleWrapper>
            <MapMetaDataEditor map={map} />
          </SpinnerWrapper>
        </OverviewPanel>
      </MainSidebar>

      <MainContent>
        <SpinnerWrapper loading={loading}>
          <MapEditor layer={map}/>
        </SpinnerWrapper>
      </MainContent>
    </>
  );
};

export default SingleMapPage;
