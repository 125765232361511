import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Hr,
  IconButton,
  InputGroup,
  Label,
  Select,
} from "../../EditorStyles";
import { ReactComponent as TrashIcon } from "./../../../../images/editor-icons/trash.svg";
import { ReactComponent as AddIcon } from "./../../../../images/editor-icons/plus.svg";
import { ReactComponent as CloseIcon } from "./../../../../images/editor-icons/x.svg";
import { ReactComponent as OpenIcon } from "./../../../../images/editor-icons/external-link.svg";
import { FormattedMessage } from "react-intl";
import { useEditState } from "../../EditContext";
import ConfirmDialog from "../../BaseComponents/ConfirmDialog";
import { useRestAuth } from "../../../../hooks/useRestAuth";
import { areCoordinatesEqual, areNumbersEqual, EditorData, EditorPanoLink, EditorPanoPoi, Picking, SelectedLink } from "../../../../utils/editorUtils";
import { CoordinateEditor } from "./CoordinateEditor";
import { Pano, PanoScenario, Poi } from "../../../../types";
import SpinnerWrapper from "../../BaseComponents/SpinnerWrapper";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../../../AppContext";

interface PanoLinkEditorProps {
  setPicking: React.Dispatch<React.SetStateAction<Picking>>;
  setSelectedLink: React.Dispatch<React.SetStateAction<SelectedLink | undefined>>;
  selectedLink: SelectedLink;
  coordinateInputsLoading: boolean;
  scenario: PanoScenario;
  onCloseEditor: () => void;
}

const PanoLinkEditor: React.FC<PanoLinkEditorProps> = ({ setPicking, setSelectedLink, selectedLink, coordinateInputsLoading, scenario, onCloseEditor }) => {
  const {
    panoLinkRepository,
    panoPoiRepository,
    data,
    editData,
    target,
  } = useEditState();
  const restAuth = useRestAuth();
  const { state } = useAppState();
  const history = useHistory();
  const [deleteLink, setDeleteLink] = useState(false);
  const linkdata: EditorPanoLink | EditorPanoPoi = useMemo(() => {
    if (selectedLink.type === "pano") {
      return (data as EditorData<"pano">).panorama_location[selectedLink.item!];
    }
    return (data as EditorData<"pano">).poi_location[selectedLink.item!];
  }, [selectedLink, data]);
  const [targetInputLoading, setTargetInputLoading] = useState<boolean>(false);
  const [typeInputLoading, setTypeInputLoading] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState(linkdata?.hotspot_coordinates || []);

  const shapes = [
    { value: "point", label: "Enkel punt", minimumPins: 0 },
    { value: "polyline", label: "Lijn (ten minste twee punten)", minimumPins: 2 },
    { value: "polygon", label: "Polygoon (ten minste drie punten)", minimumPins: 3 },
  ];

  useEffect(() => {
    setCoordinates(linkdata?.hotspot_coordinates || []);
  }, [linkdata]);


  const onDeleteLink = async () => {
    if (target) {
      if (selectedLink.type === "pano") {
        const links = await panoLinkRepository.delete(restAuth, selectedLink.item, target);
        editData<"pano">({ panorama_location: links });
      } else {
        const links = await panoPoiRepository.delete(restAuth, selectedLink.item, target);
        editData<"pano">({ poi_location: links });
      }
      setDeleteLink(false);
      onCloseEditor();
    }
  };

  const setTarget = useCallback(
    async (linkTarget: number) => {
      if (target) {
        setTargetInputLoading(true);
        if (selectedLink.type === "pano") {
          const links = await panoLinkRepository.update(
            restAuth,
            { panorama: linkTarget },
            selectedLink.item,
            target
          );
          editData<"pano">({ panorama_location: links });
        } else {
          const links = await panoPoiRepository.update(
            restAuth,
            { poi: linkTarget },
            selectedLink.item,
            target
          );
          if (links) {
            const previousLink = { ...linkdata };
            const index = Object.keys(links).findIndex((key) => {
              const link: EditorPanoPoi = links[key as keyof typeof links] as EditorPanoPoi;
              return (
                link.poi === linkTarget &&
                areCoordinatesEqual(link.hotspot_coordinates, previousLink.hotspot_coordinates) &&
                areNumbersEqual(link.pitch, previousLink.pitch) &&
                areNumbersEqual(link.yaw, previousLink.yaw)
              );
            });
            editData<"pano">({ poi_location: links });
            setSelectedLink({ type: "poi", item: index });
          }
        }
        setTargetInputLoading(false);
      }
    },
    [editData, linkdata, panoLinkRepository, panoPoiRepository, restAuth, selectedLink.item, selectedLink.type, setSelectedLink, target]
  );

  const setType = useCallback(
    async (linkType: string) => {
      if (target) {
        setTypeInputLoading(true);
        const updateLinkData: {
          hotspot_type?: string;
          yaw?: number;
          pitch?: number;
          hotspot_coordinates?: [{ pitch: number; yaw: number }];
        } = {};
        updateLinkData.hotspot_type = linkType;
        if (linkType === "point") {
          updateLinkData.hotspot_coordinates = [coordinates[0]];
          updateLinkData.yaw = coordinates[0].yaw;
          updateLinkData.pitch = coordinates[0].pitch;
        }
        if (selectedLink.type === "pano") {
          const links = await panoLinkRepository.update(
            restAuth,
            updateLinkData,
            selectedLink.item,
            target
          );
          editData<"pano">({ panorama_location: links });
        } else {
          const links = await panoPoiRepository.update(
            restAuth,
            updateLinkData,
            selectedLink.item,
            target
          );
          editData<"pano">({ poi_location: links });
        }
        setTypeInputLoading(false);
      }
    },
    [
      coordinates,
      editData,
      panoLinkRepository,
      panoPoiRepository,
      restAuth,
      selectedLink.item,
      selectedLink.type,
      target,
    ]
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>
          <FormattedMessage
            id="editor.pano.link.title"
            defaultMessage="Edit link"
            description="Text for pano link editor title"
          />
        </h1>
        <IconButton style={{ position: "relative", top: "-9px" }} onClick={onCloseEditor}>
          <CloseIcon />
        </IconButton>
      </div>
      <InputGroup>
        <Label>
          <FormattedMessage
            id="editor.link.target"
            defaultMessage="Target"
            description="Text for target input label"
          />
        </Label>
        <SpinnerWrapper loading={targetInputLoading}>
          <div style={{ display: "flex", gap: "4px" }}>
            <Select
              value={
                selectedLink.type === "pano"
                  ? (linkdata as EditorPanoLink)?.panorama
                  : (linkdata as EditorPanoPoi)?.poi
              }
              onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                setTarget(Number((e.target as HTMLSelectElement).value));
              }}
            >
              {(selectedLink.type === "pano" ? state.panos : state.pois as (Pano | Poi)[])
                .filter((linkTarget) => linkTarget.id !== target && linkTarget.scenarios.some(s => selectedLink.type === "pano" ? (s as PanoScenario).slug === scenario.slug : s === scenario.id))
                .map((linkTarget: Pano | Poi, index: number) => (
                  <option key={index} value={linkTarget.id}>
                    {linkTarget.title}
                  </option>
                ))}
            </Select>
            {selectedLink.type === "pano" && (linkdata as EditorPanoLink)?.panorama && (
              <IconButton onClick={() => history.push(`/editor/pano/${(linkdata as EditorPanoLink)?.panorama}/${state.panos.find((pano) => pano.id === (linkdata as EditorPanoLink)?.panorama)?.scenarios[0].slug}`)}>
                <OpenIcon />
              </IconButton>
            )}
          </div>
        </SpinnerWrapper>
      </InputGroup>
      <InputGroup>
        <Label>
          <FormattedMessage
            id="editor.link.shape"
            defaultMessage="Shape"
            description="Text for shape input label"
          />
        </Label>
        <SpinnerWrapper loading={typeInputLoading}>
          <Select
            value={linkdata?.hotspot_type}
            onChange={(e: React.FormEvent<HTMLSelectElement>) => {
              setType((e.target as HTMLSelectElement).value);
            }}
          >
            {shapes
              .filter((shape) => shape.minimumPins <= linkdata?.hotspot_coordinates.length)
              .map((shape, index) => (
                <option key={index} value={shape.value}>
                  {shape.label}
                </option>
              ))}
          </Select>
        </SpinnerWrapper>
      </InputGroup>
        <InputGroup>
          <Label>
            <FormattedMessage
              id="editor.link.coordinates"
              defaultMessage="Coordinates"
              description="Text for coordinates input label"
            />
          </Label>
          <SpinnerWrapper loading={coordinateInputsLoading}>
            {coordinates.map((element, index) => (
              <CoordinateEditor
                deletable={coordinates.length > 1}
                key={index}
                selectedLink={selectedLink}
                pointIndex={index}
                coordinates={coordinates || []}
              />
            ))}
            <Button className="small" onClick={() => setPicking(Picking.Coordinate)}>
              <AddIcon />
              <FormattedMessage
                id="editor.link.add_coordinate"
                defaultMessage="Add coordinate"
                description="Text for add coordinate button"
              />
            </Button>
          </SpinnerWrapper>
        </InputGroup>
      <Hr />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={() => setDeleteLink(true)}>
          <TrashIcon />
          <FormattedMessage
            id="editor.link.delete"
            defaultMessage="Delete link"
            description="Text for delete link button"
          />
        </Button>
      </div>
      <ConfirmDialog show={deleteLink} setShow={setDeleteLink} onConfirm={onDeleteLink}>
        <h2>
          <FormattedMessage
            id="editor.link.confirm_delete"
            defaultMessage="Are you sure you want to delete this link?"
            description="Confirmation message for deleting a link"
          />
        </h2>
      </ConfirmDialog>
    </>
  );
};

export default PanoLinkEditor;
