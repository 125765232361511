import React, { useEffect, useState } from "react";
import { EditorData, Picking } from "../../../utils/editorUtils";
import { FormattedMessage } from "react-intl";
import { StackedItems, Button } from "../EditorStyles";
import { ReactComponent as PanoramaIcon } from "./../../../images/editor-icons/camera.svg";
import { ReactComponent as PoiIcon } from "./../../../images/editor-icons/map-pin.svg";
import { useEditState } from "../EditContext";
import Tooltip from "../BaseComponents/Tooltip";
import { useAppState } from "../../../AppContext";
import { PanoScenario } from "../../../types";

interface PanoAddButtonsProps {
  setPicking: React.Dispatch<React.SetStateAction<Picking>>;
  scenario: PanoScenario;
}

const PanoAddButtons: React.FC<PanoAddButtonsProps> = ({ setPicking, scenario }) => {
  const { data, target } = useEditState();
  const [editorData, setEditorData] = useState<EditorData<"pano">>(data as EditorData<"pano">);
  const { state } = useAppState();
  useEffect(() => {
    setEditorData(data as EditorData<"pano">);
  }, [data]);

  const panoDisabled = !(
    state.panos.filter(pano => pano.id !== target && pano.scenarios.some(s => s.id === scenario.id)).length > 0
  );
  const poiDisabled = !(
    state.pois.length > 0 && state.pois.some(poi => poi.scenarios.some(s => s === scenario.id))
  );

  return (
    <>
      <h2>
        <FormattedMessage
          id="editor.pano.link.add_link"
          defaultMessage="Add new link to"
          description="Text for add link header"
        />
      </h2>
      <StackedItems>
        <Tooltip
          message={
            <FormattedMessage
              id="editor.pano.link.add_link.no_pano_locations"
              defaultMessage="All available panoramas have already been placed."
              description="Tooltip for disabled panorama button when no panoramas are available"
            />
          }
          disabled={!panoDisabled}
        >
          <Button
            className={`primary ${panoDisabled ? "disabled" : ""}`}
            onClick={() => setPicking(Picking.Pano)}
            disabled={panoDisabled}
          >
            <PanoramaIcon />
            <FormattedMessage
              id="editor.pano.link.add_pano"
              defaultMessage="Panorama"
              description="Text for add panorama link button"
            />
          </Button>
        </Tooltip>

        <Tooltip
          message={
            <FormattedMessage
              id="editor.pano.link.add_link.no_poi_locations"
              defaultMessage="All available pois have already been placed."
              description="Tooltip for disabled POI button when no pois are available"
            />
          }
          disabled={!poiDisabled}
        >
          <Button
            className={`primary ${poiDisabled ? "disabled" : ""}`}
            onClick={() => setPicking(Picking.Poi)}
            disabled={poiDisabled}
          >
            <PoiIcon />
            <FormattedMessage
              id="editor.pano.link.add_poi"
              defaultMessage="POI"
              description="Text for add poi link button"
            />
          </Button>
        </Tooltip>
      </StackedItems>
    </>
  );
};

export default PanoAddButtons;
